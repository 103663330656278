import React, { useState } from 'react';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import logo from './images/logo.png';
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [dob, setDOB] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showDOBField, setShowDOBField] = useState(false);
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const [passwordError] = useState('');
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const handleUsernameVerification = async () => {
    try {
      if (!username) {
        alert('Please enter your username.');
        return;
      }
      const response = await axios.post('https://apps.hygiahealthservices.com/services/Forgot_password', {
        Method: 'User',
        Username: username,
      });

      if (response.data.Response) {
        setShowDOBField(true);
        setShowNewPasswordField(false);
      } else {
        console.log('Username not found');
      }
    } catch (error) {
      console.error('Error verifying username:', error.message);
    }
  };

  const handleDOBVerification = async () => {
    try {
      console.log('DOB Verification Request Data:', {
        Method: 'DOB',
        Username: username,
        DOB: dob,
      });

      const response = await axios.post('https://apps.hygiahealthservices.com/services/Forgot_password', {
        Method: 'DOB',
        Username: username,
        DOB: dob,
      });

      console.log('DOB Verification Server Response:', response);

      if (response.data.Response === true) {
        setShowNewPasswordField(true);
        console.log('Date of Birth verified successfully. Proceeding to new password verification.');
      } else {
        console.log('Date of Birth not verified');
        alert('Date of Birth not verified. Please check the entered information.');
      }
    } catch (error) {
      console.error('Error verifying DOB:', error.message);
    }
  };

  const handleNewPasswordVerification = async () => {
    try {
      if (!newPassword || !confirmNewPassword) {
        alert('Please enter both the new password and confirm password.');
        console.log('Please enter both the new password and confirm password.');
        return;
      }
  
      if (newPassword !== confirmNewPassword) {
        alert('Passwords do not match. Please enter matching passwords.');
        console.log('Passwords do not match. Please enter matching passwords.');
        return;
      }

      const response = await axios.post('https://apps.hygiahealthservices.com/services/Forgot_password', {
        Method: 'Pwd',
        Username: username,
        Password: newPassword,
      });

      if (response.data.Response) {
        console.log('New Password created');
        setIsPasswordChanged(true);
        setUsername('');
        setDOB('');
      } else {
        console.log('New Password not verified');
      }
      console.log('Server Response:', response);
    } catch (error) {
      console.error('Error verifying new password:', error.message);
    }
  };

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      const day = selectedDate.getDate().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      setDOB(formattedDate);
    } else {
      alert("Please select a valid date.");
    }
  };

  

  return (
    
    <div className="login-container">
      <div className="lgimg-box">
        <img src={logo} alt=""/>
      </div>
      <div className="login-box mt-5">
      <div className='mb-4 mt-3 login-title'>
      <h2>Forgot Password</h2>
      </div>
        
        <div className="input-field">
          <span className='mdi mdi-account-outline p-2 label-icon'></span>
          <input className='form-control ps-4' type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter Username"/>
          <div className='text-end mt-2'>
          <button className='btn btn-success btn-sm' onClick={handleUsernameVerification}>Verify</button>
          </div>
        </div>

        {showDOBField && (
          <div className="input-field">
          <span className='mdi mdi-calendar-month p-2 label-icon'></span>
            <ReactDatePicker
              selected={dob ? new Date(dob) : null}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"  
              inputFormat="yyyy-MM-dd"  
              placeholderText="Enter your Date of Birth (yyyy-MM-dd)"  
              className="form-control"
              customInput={
                <input
                  type="text"
                  className="form-control ps-4 "
                  placeholder="Enter your Date of Birth (YYYY/MM/DD)"
                />
              }
              showYearDropdown
              scrollableYearDropdown
              maxDate={addDays(new Date(), 0)}
              previousMonthButtonLabel={<span className="black-text">&#60;</span>}
              nextMonthButtonLabel={<span className="black-text">&#62;</span>}
            />
            <div className='text-end mt-2'>
            <button className='btn btn-success btn-sm' onClick={handleDOBVerification}>Verify</button>
            </div>
          </div>
        )}

        {showNewPasswordField && !isPasswordChanged && (
          <div>
          <label class="pb-2">New Password:</label>
          <div className="input-field mb-3 position-relative">
            
            <span class="mdi mdi-lock-outline p-2 label-icon"></span>
            <input
                type={showPassword ? "text" : "password"}
                className="form-control ps-4"
                value={newPassword}
                id="password"
                onChange={(e) => setNewPassword(e.target.value)}
            />

            <label className="ml-2 checkbox-label checkbox-label input eye-icon">
              <input
                type="checkbox"
                id="showPassword"
                onChange={() => setShowPassword(!showPassword)}
              />
              <i className={showPassword ? "mdi mdi-eye" : "mdi mdi-eye-off"}></i>
            </label>

            </div>
            <label class="pb-2">Confirm New Password:</label>
            <div className="input-field position-relative">
            
            <span class="mdi mdi-lock-outline p-2 label-icon"></span>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control ps-4"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />

            <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                <input
                  type="checkbox"
                  id="showPassword"
                  onChange={() => setShowConfirmPassword(!showConfirmPassword)}
                />
                <i className={showPassword ? "mdi mdi-eye" : "mdi mdi-eye-off"}></i>
            </label>

            </div>
            <div className='text-end mt-2'>
              <button className='btn btn-success btn-sm' onClick={handleNewPasswordVerification}>Submit</button>
            </div>
            {passwordError && <p>{passwordError}</p>}
          </div>
        )}

        {isPasswordChanged && (
          <div>
            <p>Password has been changed successfully!</p>
          </div>
        )}
      </div>
      <div className="btn-back">
        <Link to="/" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
