import React, {useState, useEffect} from "react";
import logo from './images/logo.png';
import { Link } from 'react-router-dom';

const AvatarPage = () => {
  const [user, setUser] = useState({});
  const ID = localStorage.getItem("User_session");
  const externalURL = `https://apps.hygiahealthservices.com/avatar/TalkAvatar/?id=${ID}`;

  const navigateToExternalURL = () => {
    window.location.href = externalURL;
  };
  const selectedPractice = localStorage.getItem("selectedPractice");

  useEffect(() => {
    const username = localStorage.getItem("username");
    const getlitem = localStorage.getItem("User_session");
    console.log(getlitem);

    if (getlitem) {
      fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${getlitem}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          setUser({
            name: username, 
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("userId not found in local storage");
    }
  }, []);

  return (
    <div className="login-container chat-page">
    <div className="lgimg-box">
       <img src={logo} alt=""/>
       <p className="pt-2 text-white">
       <i>Welcome <strong>{user.name}</strong></i></p>
        {selectedPractice && (
          <>
            <br />
             Practice: <strong>{selectedPractice}</strong>
          </>
        )}
     </div>
    <div>
      <button onClick={navigateToExternalURL} style={{alignItems: 'center', marginTop: '10px', justifyContent:'center'}}>Link to avatar</button>
    </div>
    <div className="btn-back">
        <Link to="/main-page" title="Back">
            <span class="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    </div>
  );
};

export default AvatarPage;
