import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import logo1 from './images/logo.png';

const ChatFunctions = () => {
    const [user, setUser] = useState({});
    const [functions, setFunctions] = useState([]);
    const [responseSystemText, setResponseSystemText] = useState('');
  
    useEffect(() => {
        const ID = localStorage.getItem("ID");
    
        if (ID) {
          axios.get(`http://192.168.0.37:5000/Get_details?id=${ID}`)
            .then((response) => {
              if (!response.data.ok) {
                console.error("API error:", response.data);
                throw new Error(`API error! Message: ${response.data.message || 'Unknown error'}`);
              }
    
              const data = response.data.data;
              console.log("API Data:", data);
    
              setUser({
                name: data.name,
              });
    
              // Ensure Functions is an array with correct structure
              const functionsArray = Array.isArray(data.Functions) ? data.Functions : [];
              setFunctions(functionsArray);
              
              setResponseSystemText(data.Response);
            })
            .catch((error) => {
              console.error("Error fetching data:", error.message);
            });
        } else {
          console.error("userId not found in local storage");
        }
      }, []);
    
      console.log("User:", user);
      console.log("Functions:", functions);
      console.log("Response System Text:", responseSystemText); 
    
  
    return (
      <div className="login-container dt-page">
        <div className="lgimg-box">
          <img src={logo1} alt="" />
          <p className="pt-4 text-white"><i>Welcome <strong>{user.name}</strong></i></p>
        </div>
        <div className='dt-box mt-2'>
          <div className='row'>
            <div className='col'><h3 className='p-2'>Chat Functions</h3></div>
          </div>
          <div className="description">
  <table className="table">
    <thead>
      <tr>
        <th>Function</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      {functions.map((func, index) => (
        <tr key={index}>
          <td>{func[0]}</td>
          <td>{func[1]}</td>
        </tr>
      ))}
    </tbody>
  </table>
  <p>{responseSystemText}</p>
</div>

        </div>
        <div className="btn-back">
          <Link to="/main-page" title="Back">
            <span className="mdi mdi-arrow-left text-white"></span>
          </Link>
        </div>
      </div>
    );
  };
  
  export default ChatFunctions;
