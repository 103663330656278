import React, { useState, useEffect, useCallback } from "react";
import './ChatComponent.css';
import { Link } from 'react-router-dom';
import logo1 from './images/logo.png';


const APIDataDisplay = () => {
  const ID = localStorage.getItem("ID");
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState({});
  const [apiMessages, setApiMessages] = useState([]);
  const [tableContent, setTableContent] = useState('');
  const mode = "C";
  const securityKey = "79d0ca9e-5d22-11ee-8c99-0242ac120002";

  const appendMessage = (role, content) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: role, content: content },
    ]);
  };

  const fetchInitialChatData = useCallback(() => {
    const url = `http://3.82.227.19:80/initial_text`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.messages && Array.isArray(data.messages)) {
          const filteredMessages = data.messages.filter(
            (message) => message.role !== "function"
          );
          setMessages(filteredMessages);
        } else {
          console.error("Invalid initial chat data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching initial chat data:", error);
      });
  }, []);

  const sendMessage = useCallback(() => {
    const userMessage = userInput;
    setUserInput("");

    appendMessage("user", userMessage);

    fetch("http://3.82.227.19:80/send_message_HCC", {
  method: "POST",
  body: JSON.stringify({ message: userMessage, Patient_ID: ID, Mode: mode }),
  headers: {
    "Security-Key": securityKey,
    "Content-Type": "application/json",
  },
})
  .then((response) => response.json())
  .then((data) => {
    const assistantResponse = data.Response;
    appendMessage("assistant", assistantResponse);

    
    const tableContainer = document.getElementById("table-container");
    tableContainer.innerHTML = data.Table;
  })
  .catch((error) => console.error("Error:", error));
}, [userInput, ID]);


  const clearChat = useCallback(() => {
    setMessages([]);
    setApiMessages([]);
    setTableContent(''); 
    fetch("http://3.82.227.19:80/clear-HCC", {
      method: "POST",
    })
      .then(() => {
        fetchInitialChatData();
      })
      .catch((error) => {
        console.error("Error clearing chat:", error);
      });
  }, [fetchInitialChatData]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  

  useEffect(() => {
    const username = localStorage.getItem("username");
    const ID = localStorage.getItem("ID");
  
    if (ID) {
      fetch(`http://3.82.227.19:80/Get_details?id=${ID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setUser({
            name: username,
          });
          setTableContent('');
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("userId not found in local storage");
    }
  }, []);
  

  useEffect(() => {
    const sendButton = document.getElementById("send-button");
    const clearButton = document.getElementById("clear-button");

    const handleSendMessage = () => {
      sendMessage();
    };

    const handleClearChat = () => {
      clearChat();
    };

    sendButton.addEventListener("click", handleSendMessage);
    clearButton.addEventListener("click", handleClearChat);

    return () => {
      sendButton.removeEventListener("click", handleSendMessage);
      clearButton.removeEventListener("click", handleClearChat);
    };
  }, [sendMessage, clearChat]);

  useEffect(() => {
    fetchInitialChatData();
  }, [ID, fetchInitialChatData]);

  return (
    <div className="login-container chat-page">
      <div className="lgimg-box">
        <img src={logo1} alt="" />
        <p className="pt-2 text-white">
          <i>Welcome <strong>{user.name}</strong></i>
        </p>
      </div>
      <ul class="nav nav-tabs" id="chhc-tabs" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active btn me-2" id="home-tab" data-bs-toggle="tab" data-bs-target="#chat" type="button" role="tab" aria-controls="Chat" aria-selected="true">Chat</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#result" type="button" role="tab" aria-controls="result" aria-selected="false">Result</button>
  </li>
  
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="chat" role="tabpanel" aria-labelledby="chat-tab">
  <div id="chat-container">
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              <span className={`prefix prefix-${message.role}`}>
                {message.role.charAt(0).toUpperCase() + message.role.slice(1)}:
              </span>{" "}
              {typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}
            </div>
          ))}
        </div>
      </div>
  </div>
  <div class="tab-pane fade" id="result" role="tabpanel" aria-labelledby="result-tab">
  <div id="table-container">
      {apiMessages.map((index) => (
        <div key={index}>
          {/* Display the tableContent here */}
          <div dangerouslySetInnerHTML={{ __html: tableContent }} />
        </div>
      ))}
      </div>
  </div>
</div>
      
      
      <div id="input-container">
        <input
          id="user-input"
          placeholder="Type your question here... "
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button id="send-button" title="Send" className="send-button">
          <span className="mdi mdi-send"></span>
        </button>
        <button
          id="clear-button"
          title="Clear Chat"
          className="btn btn-secondary clear-button"
          onClick={clearChat}
        >
          <span className="mdi mdi-eraser"></span>
        </button>
      </div>
      <div className="btn-back">
        <Link to="/main-page" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
      <div className="api-message-box">
        <div className="api-message-container">
          <ul>
            {apiMessages.map((message, index) => (
              <li key={index}>{message.content}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default APIDataDisplay;
