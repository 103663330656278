import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import logo from './images/logo.png';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [manualResponse, setManualResponse] = useState('');
  const [patientId, setPatientId] = useState(2); 
  const [user, setUser] = useState({});
  const selectedPractice = localStorage.getItem("selectedPractice");
  const userID = localStorage.getItem("ID");
  const filteredData = data.filter((row) => row[0] > 0); 
  console.log('Filtered Data:', filteredData);

  useEffect(() => {
    setPatientId(userID); 
  }, [userID]);

  useEffect(() => {
    const apiUrl = `https://apps.hygiahealthservices.com/services/retrive_data?id=${patientId}`;
  
    axios.post(apiUrl)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData(response.data);
          console.log('Data fetched successfully:', response.data);
        } else {
          console.error('Invalid data format:', response.data);
          setData([]); 
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [patientId]);

  useEffect(() => {
    const savedManualResponse = localStorage.getItem('manualResponse');
    if (savedManualResponse !== null) {
      setManualResponse(savedManualResponse);
    }
  }, []);

  useEffect(() => {
    // const username = localStorage.getItem("username"); 
    //const ID = localStorage.getItem("ID");
    const userSession = localStorage.getItem("User_session");
  
    if (userSession) {
      fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${userSession}`, {
        headers: {
          'User_session': userSession,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); 
        })
        .then((userData) => {
          console.log("Get_details API Response:", userData);
  
          const usernameFromAPI = userData.Response && userData.Response[0];
  
          if (usernameFromAPI) {
            setUser({
              name: usernameFromAPI,
            });
          } else {
            console.error("Username not found in the response");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    } else {
      console.error("userId not found in local storage");
    }
  }, []);

  const handleEditClick = (id, manualResponse) => {
    setEditingRowId(id);
    setManualResponse(manualResponse || ''); 
  };

  const handleSaveClick = (id) => {
    const apiUrl = `https://apps.hygiahealthservices.com/services/manualResponse`;
  
    axios
    .post(apiUrl, { Text: manualResponse, ID: id }) 
    .then((response) => {
      console.log('Manual response updated successfully in the database:', response.data);
      const updatedData = data.map((row) => {
        if (row[0] === id) {
          return [row[0], row[1], row[2], row[3], row[4], manualResponse];
        }
        return row;
      });
  
        setData(updatedData);
        setEditingRowId(null);
      })
      .catch((error) => {
        console.error('Error updating manual response:', error);
      });
  };

  useEffect(() => {
    const savedManualResponse = localStorage.getItem(`manualResponse_${editingRowId}`);
  
    if (savedManualResponse !== null) {
      setManualResponse(savedManualResponse);
    }
  }, [editingRowId]);

  // useEffect(() => {
  //   const username = localStorage.getItem("username");
  //   const ID = localStorage.getItem("ID");
  
  //   if (ID) {
  //     fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${ID}`)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! Status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then(() => {
  //         console.log('User details fetched successfully');
          
  //         setUser({
  //           name: username,
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   } else {
  //     console.error("userId not found in local storage");
  //   }
  // }, []);
  

return (
  <div className="login-container dt-page">
   <div className="lgimg-box">
      <img src={logo} alt=""/>
      <p className="pt-4 text-white">
      <i>Welcome <strong>{user.name}</strong>
        {selectedPractice && (
          <>
            <br />
            Practice: <strong>{selectedPractice}</strong>
          </>
        )}
        </i>
        </p>
    </div>
    <div className='dt-box mt-2'>
    <div className='row'>
      <div className='col' style={{fontSize: '30px'}}><h3 className='p-2'>HYGIA TESTMONIAL SCREEN</h3></div>
      <div className='col text-end'>
      <div className='mb-2 text-end mt-2'>
  <label htmlFor="patientId">Patient ID: </label>
  <input
    className='mx-3'
    type="text"
    id="patientId"
    value={patientId}
    onChange={(e) => {
    // Use a regular expression to allow only numeric values
    const numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Update the state only if the value is numeric
    if (numericValue !== e.target.value) {
      setPatientId(numericValue);
    } else {
      setPatientId(e.target.value);
    }
  }}
  />
</div>

      </div>
    </div>
    
   
    <div className="table-responsive tb-stycky">
    {filteredData.length === 0 ? (
    <p className="text-center mt-3" style={{color: 'red'}}>
      {patientId ? 'No data found for the entered ID' : 'Please enter a valid Patient ID'}
    </p>
  ) : (
    <div>
      <table className="table table-bordered table-striped m-0">
      <thead className="bg-dark text-white bg-primary">
  <tr>
    <th>ID</th>
    <th className='text-nowrap'>Date Time</th> 
    <th>Voice Text</th>
    <th>Response Input</th>
    <th>Response Text</th>
    <th className='text-nowrap'>Manual Response</th>
  </tr>
</thead>
        <tbody>
          {filteredData.map((row) => (
            <tr key={row[0]}>
              <td>{row[0]}</td>
              <td>{row[1]}</td>
              <td>{row[2]}</td>
              <td>{row[3]}</td>
              <td>{row[4]}</td>
              <td>
                {editingRowId === row[0] ? (
                  <div>
                    <textarea
                      value={manualResponse}
                      onChange={(e) => setManualResponse(e.target.value)}
                    ></textarea>
                    <div className="text-end">
                      <button className="btn btn-primary btn-sm" onClick={() => handleSaveClick(row[0])}>
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <div>{row[5]}</div>
                    <div className="text-end">
                      <button className="btn btn-secondary btn-sm" onClick={() => handleEditClick(row[0], row[5])}>
                        Edit
                      </button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}
    </div>
    </div>
    
    <div className="btn-back">
        <Link to="/main-page" title="Back">
            <span class="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    
  </div>
);

};

export default DataTable;