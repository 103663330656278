import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from './images/logo.png';

const MainPage = () => {
  const [user, setUser] = useState({});
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(true);
  const userSession = localStorage.getItem("User_session");
  const systemText = localStorage.getItem("systemText");
  const [practiceSessionStarted, setPracticeSessionStarted] = useState(false); 
  const externalURL = `https://apps.hygiahealthservices.com/avatar/TalkAvatar/?id=${userSession}`;

  const navigateToExternalURL = () => {
    window.location.href = externalURL;
  };

  const fetchPracticeOptions = async () => {
    try {
      const response = await fetch('https://apps.hygiahealthservices.com/services/practice_code', {
        method: 'GET',
        headers: {
          'User_session': userSession,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch practice options. Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data && Array.isArray(data.Practice_codes)) {
        setPracticeOptions(data.Practice_codes);
      } else {
        throw new Error('Invalid or missing practice options data');
      }
    } catch (error) {
      console.error('Error fetching practice options:', error.message);
    }
  };
  
  useEffect(() => {
    const username = localStorage.getItem("username");
    const userSession = localStorage.getItem("User_session");
    const getlitem = localStorage.getItem("User_session");
    console.log("Username:", username);
    console.log(getlitem);
    
    const storedPractice = localStorage.getItem("selectedPractice");
    if (storedPractice) {
      setSelectedPractice(storedPractice);
      setShowDropdown(false);
    }

    const isNavigatingBack =
      window.performance && window.performance.navigation.type === 2;

    if (isNavigatingBack) {
      setShowDropdown(false);
    }

    if (getlitem) {
      fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${getlitem}`, {
        headers: {
          'User_session': userSession,
        },
      })
        .then((response) => {
          console.log("Get_details API Response:", response);

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((userData) => {
          console.log("User Data:", userData);
          const usernameFromAPI = userData.Response && userData.Response.username;

          if (usernameFromAPI) {
            console.log("Username from API:", usernameFromAPI);

            setUser({
              name: usernameFromAPI,
            });
          } else {
            console.error("Username not found in the response");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("SessionID not found in the location state");
    }
  }, []);

  useEffect(() => {
    const userSession = localStorage.getItem("User_session");
    console.log("User Session:", userSession);
  
    if (userSession) {
      fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${userSession}`, {
        headers: {
          'User_session': userSession,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((userData) => {
          console.log("Get_details API Response:", userData);
  
          const usernameFromAPI = userData.Response && userData.Response[0];
  
          if (usernameFromAPI) {
            setUser({
              name: usernameFromAPI,
            });
          } else {
            console.error("Username not found in the response");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    } else {
      console.error("User session not found in local storage");
    }
  }, [userSession]);

  const handlePracticeButtonClick = () => {
    fetchPracticeOptions();
    setShowDropdown(true);
  };
  
  const handlePracticeChange = async(selectedPractice) => {
    setSelectedPractice(selectedPractice);
    setShowDropdown(false);
    localStorage.setItem("selectedPractice", selectedPractice);
    if (selectedPractice) {
      await startPracticeSession(selectedPractice);
    }
  };

  const filteredPracticeOptions = practiceOptions
    .map((practice) => String(practice)) 
    .filter((practice) =>
      practice.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const startPracticeSession = async (practiceCode) => {
      try {
        if (!practiceSessionStarted) {
          setPracticeSessionStarted(true);
          const apiUrl = 'https://apps.hygiahealthservices.com/services/practice_session';
          const sessionData = {
            User_session: userSession,
            Practice_code: practiceCode,
          };
          //console.log('Start Practice Session - Session Data:', sessionData.User_session);
          console.log('Start Practice Session - Session Data:', sessionData.Practice_session);
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(sessionData),
          });
  
          if (response.status === 200) {
            const practiceSessionData = await response.json();
            const practiceSession = practiceSessionData.Practice_session;
  
            localStorage.setItem("practiceCode", practiceCode);
            localStorage.setItem("practiceSession", practiceSession);
            // console.log('Practice session started successfully:', practiceSession);
            return { success: true, practiceSession };
          } else {
            console.error('Failed to start practice session');
            return { success: false };
          }
        }
      } catch (error) {
        console.error('Error:', error);
        return { success: false };
      }
    };

    const handleButtonClick = async (practiceCode) => {
      console.log('Button Clicked - Practice Code:', practiceCode);
  
      const { success, practiceSession } = await startPracticeSession(practiceCode);
  
      if (success) {
        setShowDropdown(false);
        localStorage.setItem("selectedPractice", practiceCode);
        localStorage.setItem("practiceCode", practiceCode);
        localStorage.setItem("practiceSession", practiceSession);
      } else {
        console.error('Failed to start practice session');
      }
    };  

    const handleLogout = (e) => {
  if (window.confirm("Are you sure you want to log out?")) {
    const userSession = localStorage.getItem("User_session");
    console.log("Logging out with User Session:", userSession);
    console.log("system text cleared", systemText);
    localStorage.clear();

    fetch(`https://apps.hygiahealthservices.com/services/logout?id=${userSession}`, {
      method: "GET",
    })
      .then(() => {
        setUser({});
        setSelectedPractice(null);
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  } else {
    e.preventDefault();
  }
    };
    
    return (
      <div className="login-container">
        <div className="lgimg-box">
          <img src={logo} alt="" />
          <p className="pt-4 text-white">
            <i>Welcome <strong>{user.name}</strong>
              {selectedPractice && (
                <>
                  <br />
                  Practice: <strong>{selectedPractice}</strong>
                </>
              )}
            </i>
          </p>
        </div>
        <div className="btn-main d-flex">
          <Link to="/apidatadisplay">
            <button style={{ marginRight: '20px', display: 'none'}} className="btn btn-info">
              <span className="mdi mdi-chat"></span> HCC
            </button>
          </Link>
          <button onClick={navigateToExternalURL} style={{ marginRight: '20px' }} className="btn btn-dark">
            <span className="mdi mdi-chat"></span> Avatar
          </button>
          <Link to="/chat-component">
            <button style={{ marginRight: '20px' }} className="btn btn-success">
              <span className="mdi mdi-chat"></span> Chat
            </button>
          </Link>
          <div className="dropdown" style={{ marginRight: '20px', display:'none' }}>
        {showDropdown && (
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="practiceDropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={handlePracticeButtonClick}
          >
            {selectedPractice ? `Practice: ${selectedPractice}` : 'Select Practice'}
          </button>
        )}
        {showDropdown && (
          <div className="dropdown-menu" aria-labelledby="practiceDropdown">
            <input
              type="text"
              placeholder="Search Practice"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {filteredPracticeOptions.map((practice, index) => (
              <button
                key={index}
                className="dropdown-item"
                onClick={() => {
                  handlePracticeChange(practice);
                  handleButtonClick(practice);
                }}
              >
                {practice}
              </button>
            ))}
          </div>
        )}
      </div>
          <Link to="/chatspeech">
            <button style={{ marginRight: '20px', display: 'none' }} className="btn btn-warning">
              <span className="mdi mdi-chat"></span> Chat Speech
            </button>
          </Link>
          <Link to="/datatable">
            <button style={{ marginRight: '20px' }} className="btn btn-primary">
              <span className="mdi mdi-frequently-asked-questions"></span> Testimonials
            </button>
          </Link>
          <Link to="/settings">
            <button className="btn btn-danger">
              <span className="mdi mdi-cog-outline"></span> Settings
            </button>
          </Link>
          <Link to="/chatfunctions" style={{ display: 'none' }}>
            <button className="btn btn-warning">
              <span className="mdi mdi-chat"></span> Functions
            </button>
          </Link>
        </div>
        <div className="logout">
        <Link
          to="/login"
          className="text-white"
          title="Logout"
          onClick={handleLogout}
        >
          <span className="mdi mdi-power text-white"></span>
        </Link>
      </div>
      </div>
    );
    
};

export default MainPage;
