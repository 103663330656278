import React, { useState, useEffect, useCallback, useMemo } from "react";
import './ChatComponent.css';
import { Link } from 'react-router-dom';
//import logo from './images/HYGIA_logo.jpg';
import logo1 from './images/logo.png';

const ChatSpeech = () => {
  const ID = localStorage.getItem("ID");
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState({});
  const [isRecording, setIsRecording] = useState(false);
  const mode = "C";
  const securityKey = "79d0ca9e-5d22-11ee-8c99-0242ac120002";

  const appendMessage = (role, content) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: role, content: content },
    ]);
  };

  const fetchInitialChatData = useCallback(() => {
    const url = `http://3.82.227.19:80/Hygia_chat?id=${ID}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.messages && Array.isArray(data.messages)) {
          const filteredMessages = data.messages.filter(
            (message) => message.role !== "function"
          );
          setMessages(filteredMessages);
        } else {
          console.error("Invalid initial chat data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching initial chat data:", error);
      });
  }, [ID]);

  const speakResponse = (response) => {
    const utterance = new SpeechSynthesisUtterance(response);
    speechSynthesis.speak(utterance);
  };
  

  const sendMessage = useCallback(() => {
    const userMessage = userInput;
    setUserInput("");

    appendMessage("user", userMessage);

    fetch("http://3.82.227.19:80/Hygia_message", {
      method: "POST",
      body: JSON.stringify({ Text: userMessage, Patient_ID: ID, Mode: mode }),
      headers: {
        "Security-Key": securityKey,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())
    .then((data) => {
      const assistantResponse = data.Response;
      appendMessage("assistant", assistantResponse);
      // Speak the assistant's response
      speakResponse(assistantResponse);
    })
    .catch((error) => console.error("Error:", error));
  }, [userInput, ID]);

  const clearChat = useCallback(() => {
    setMessages([]);
    const url = `http://3.82.227.19:80/clear-chat?id=${ID}`;
  
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        fetchInitialChatData();
      })
      .catch((error) => {
        console.error("Error clearing chat:", error);
      });
  }, [ID, fetchInitialChatData]);
  

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const recognition = useMemo(() => {
    const recog = new window.webkitSpeechRecognition();
    recog.continuous = true;
    return recog;
  }, []);

  useEffect(() => {
    const startRecording = () => {
      setIsRecording(true);
      recognition.start();
    };

    const stopRecording = () => {
      setIsRecording(false);
      recognition.stop();
    };

    recognition.onresult = (event) => {
      const lastResultIndex = event.results.length - 1;
      const recognizedText = event.results[lastResultIndex][0].transcript;
      setUserInput(recognizedText);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording, recognition]);

  useEffect(() => {
    const username = localStorage.getItem("username");
    const ID = localStorage.getItem("ID");

    if (ID) {
      fetch(`http://3.82.227.19:80/Get_details?id=${ID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          setUser({
            name: username, 
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("userId not found in local storage");
    }
  }, []);

  useEffect(() => {
    const sendButton = document.getElementById("send-button");
    const clearButton = document.getElementById("clear-button");

    const handleSendMessage = () => {
      sendMessage();
    };

    const handleClearChat = () => {
      clearChat();
    };

    sendButton.addEventListener("click", handleSendMessage);
    clearButton.addEventListener("click", handleClearChat);

    return () => {
      sendButton.removeEventListener("click", handleSendMessage);
      clearButton.removeEventListener("click", handleClearChat);
    };
  }, [sendMessage, clearChat]);

  useEffect(() => {
    fetchInitialChatData();
  }, [ID, fetchInitialChatData]);

  

  return (
    <div className="login-container chat-page">
      <div className="lgimg-box">
         <img src={logo1} alt=""/>
         <p className="pt-2 text-white"><i>Welcome <strong>{user.name}</strong></i></p>
       </div>
      <div id="chat-container">
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              <span className={`prefix prefix-${message.role}`}>
                {message.role.charAt(0).toUpperCase() + message.role.slice(1)}:
              </span>{" "}
              {message.content}
            </div>
          ))}
        </div>
      </div>
      <div id="input-container">
        <input
          id="user-input"
          placeholder="Type your question here... "
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button 
          id="send-button" 
          title="Send" 
          className="send-button"
          onClick={sendMessage}>
          <span className="mdi mdi-send"></span>
        </button>
        <button 
          id="clear-button" 
          title="Clear Chat" 
          className="btn btn-secondary clear-button" 
          onClick={clearChat}>
          <span className="mdi mdi-eraser"></span>
        </button>
        <button 
          className="btn btn-outline-danger rounded-circle" 
          id="startRecognition" 
          title="Record" 
          style={{height: 42, marginLeft: 4}}
          onClick={() => setIsRecording(!isRecording)}
        >
          <span className={`mdi ${isRecording ? 'mdi-microphone-off' : 'mdi-microphone'}`}></span>
        </button>
      </div>
      <div className="btn-back">
        <Link to="/main-page" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    </div>
  );
};

export default ChatSpeech;