import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import logo from './images/logo.png';
//import { GoogleLogin } from '@react-oauth/google';

const GoogleSignInPage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const gmail = localStorage.getItem('gEmailID') || "";
  const gname = localStorage.getItem('gName') || "";
  console.log('Mail:', gmail);
  console.log('Name:', gname);

useEffect(() => {
  setName(gname);
  setEmail(gmail);
    // console.log('Name:', name);
    // console.log('Email:', email);
  }, [gname, gmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const apiUrl = "https://apps.hygiahealthservices.com/services/Login_Gmail";
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Method: "R",
          Name: name,  
          Gmail: email, 
          DOB: dob,
          Gender: gender,
        }),
      });
  
      if (response.status === 200) {
        const responseData = await response.json();
        console.log("Response data from Gmail login:", responseData);
          navigate('/login');
      } else {
        console.log("Gmail login failed. Please try again.");
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Error during Gmail login:", error);
    }
    console.log('DOB:', dob); 
    console.log('Gender:', gender); 
 
  };

  return (
    <div>
      <div className="login-container">
        <div className="lgimg-box">
          <img src={logo} alt="" />
        </div>
        <div className="login-box mt-5">
          <div className="mb-4 mt-3 login-title">
            <h2>Google Sign-In</h2>
          </div>
          {/* GoogleLogin component for sign-in */}
          {/* <GoogleLogin
            clientId="1096835915922-2d08ral04uae5danerejnubnvhulr528.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle} 
            scope="profile email"
          /> */}
          {/* {email && <p>User Email: {email}</p>} */}
          <form onSubmit={handleSubmit}>
          <div className="mb-2"><label>Name:</label><strong> {gname}</strong></div>
          <div  className="mb-2"><label>Email Id:</label><strong> {gmail}</strong></div>
            <div className="mb-2">
              <label>Date of Birth:</label>
              <input
              className="form-control"
                type="date"
                value={dob}
                onChange={(e) => setDOB(e.target.value)}
                required
              />
            </div>
            <div className="mb-2">
              <label>Gender:</label>
              <select className="form-select"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                required
              >
                <option value="" disabled>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div>
            <div className="text-end">
            <button className="btn btn-primary" type="submit">Submit</button>
            </div>
             
            </div>
          </form>
        </div>
      </div>
      <div className="btn-back">
        <Link to="/create-account" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    </div>
  );
};

export default GoogleSignInPage;
