import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './images/logo.png';
import 'react-datepicker/dist/react-datepicker.css';
import './Login.css';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const userSession = localStorage.getItem("User_session");
 
  const [name, setName] = useState("");
  const [usernames, setUsernames] = useState("");
  const [passwords, setPasswords] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [passwordTyping, setPasswordTyping] = useState(false);
  const [dob] = useState("");
  const [gender, setGender] = useState("");
    
  console.log("Session ID:", userSession); 

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Login submit
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (username.trim() === '' || password.trim() === '') {
      alert("Please enter both username and password.");
      return; 
    }
  
    const data = {
      Username: username.toLowerCase(), 
      Password: password,
      Method: "P",
    };
  
    try {
      const response = await fetch('https://apps.hygiahealthservices.com/services/Login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      console.log("Response status:", response.status);
      if (response.status === 200) {
        const responseData = await response.json();
        const abcd = response.headers.get('abc');
        console.log('abc:', abcd);
        console.log("Response Data:", responseData);
        if (responseData.Response === true) {
          const ID = responseData.ID;
          const SessionID = responseData['Session-ID'];
          localStorage.setItem("ID", ID);
          localStorage.setItem("username", username);
          localStorage.setItem("User_session", SessionID);
          console.log("UserId:", responseData.ID);
          console.log('All Headers', response.headers);
          data.SessionID = SessionID;
          navigate('/main-page');
        } else {
          alert("Login unsuccessful. " + (responseData.errorMessage || "Please check your username and password."));
        } 
      } else if (response.status === 404) {
         navigate('/forgot-password');
      } else {
        alert("Login failed. Please check your username and password. Status: " + response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while logging in. Please try again later.");
    }
  };

  // Gmail Login
  const handleLogin = async () => {
    try {
      const apiUrl = "https://apps.hygiahealthservices.com/services/Login_Gmail";
      const gmail = localStorage.getItem('gEmailID');
      const gname = localStorage.getItem('gName');
      console.log('Mail:', gmail);
      console.log('Name:', gname);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Method: "L",
          Gmail: gmail, 
        }),
      });
  
      console.log("Gmail Login API Response:", response); 
  
      if (response.status === 200) {
        const responseData = await response.json();
        console.log("Response Data from Gmail Login API:", responseData); // Log the response data
        const SessionID = responseData['Session-ID']; // Extract session ID from response
        localStorage.setItem("User_session", SessionID); // Store session ID in local storage
        console.log("User Session:", SessionID);
        navigate('/main-page');
      } else {
        console.log("Gmail login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during Gmail login:", error);
    }
  };

  const signUpButton = () => {
    const container = document.getElementById('container');
    if (container) {
    container.classList.toggle("right-panel-active");
    }
  }

  const  signInButton = () =>{
    const container = document.getElementById('container');
    if (container) {
      container.classList.toggle("right-panel-active");
      }
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePasswordTyping = () => {
    setPasswordTyping(true);
  };

  const handlePasswordBlur = () => {
    setPasswordTyping(false);
  };

  const checkPasswordStrength = (value) => {
    setPasswordStrength({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handleUsernameChanges = (e) => {
    setUsernames(e.target.value);
  };

  const handlePasswordChanges = (e) => {
    let value = e.target.value;

    if (value.length > 30) {
      value = value.slice(0, 30);
    }

    setPasswords(value);
    setPasswordError("");
    checkPasswordStrength(value);
  };

  const isPasswordValid = () => {
    const passwordLength = passwords.length;
    return (
      passwordLength >= 8 &&
      passwordLength <= 30 &&
      passwordStrength.uppercase &&
      passwordStrength.lowercase &&
      passwordStrength.number &&
      passwordStrength.specialChar
    );
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
  
    if (!name || !usernames || !passwords || !confirmPassword || !dob || !gender) {
      setPasswordError("Please enter data in all fields.");
      return;
    }
  
    if (passwords !== confirmPassword) {
      setPasswordError("Passwords didn't match");
      return;
    }
  
    const dobDate = typeof dob === 'string' ? new Date(dob) : dob;

    const data = {
      Username: usernames,
      Password: passwords,
      Name: name,
      DOB: dobDate ? dobDate.toISOString() : "",
      Gender: gender,
    };

    console.log("Submitting data to server:", data);
    console.log("Name:", name);
    console.log("Username:", usernames);
    console.log("Password:", passwords);
    console.log("Confirm Password:", confirmPassword);
    console.log("DOB:", dob);
    console.log("Gender:", gender);

  
    try {
      const response = await fetch('https://apps.hygiahealthservices.com/services/Signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log("Server response:", response);
  
      if (response.status === 200) {
        const responseData = await response.json();
        console.log("Response data from server:", responseData); 
        if (responseData.ID) {
          setAccountCreated(true);
          window.alert("Account Created Successfully");
  
          setTimeout(() => {
            navigate('/');
          }, 2000);
        } else if (responseData.usernamesExists) {
          if (responseData.existingUsernames) {
            const lowercaseExistingUsernames = responseData.existingUsernames.map((u) => u.toLowerCase());
            
            if (lowercaseExistingUsernames.includes(usernames.toLowerCase())) {
              alert("Username already exists.");
              return; 
            }
          }
        } else {
          console.log("Server returned an error:", response.status); 
          alert("Account creation failed. Please try again.");
        }
      } else {
        alert("Account creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the account.");
    }
  }; 
  
  return (
    <div>
      <div class="container right-panel-active" id="container">
      <div class="form-container sign-up-container">
                <div className="logo">
                  <img src="https://lwfiles.mycourse.app/6500c6e26d2b1d1b30da7b5e-public/123e53dff6aa2371ee8ad523eea93c99.png" className="img-fluid" alt="" title="HYGIA"/>
                  </div>
                  <div className="loginbox">
                  
                  <div className="mb-3 mt-3 login-title">
          <h2 className="fs-6 mb-1">Welcome Back :)</h2>
          <span>Login to continue</span>
        </div>
                <form onSubmit={handleSubmit}>
          <div class="form-group py-1">
            <div class="input-field position-relative">
              <span class="mdi mdi-account label-icon"></span>
              <input
                type="text"
                className="form-control"
                value={username}
                onChange={handleUsernameChange}
                placeholder="Enter Username"
              />
            </div>
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
              <span className="mdi mdi-lock label-icon"></span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter Password"
              />
              <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                <input
                  type="checkbox"
                  onChange={() => setShowPassword(!showPassword)}
                />
                <i className="mdi"></i>
              </label>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="remember"/>
              <label class="form-check-label" for="remember">
              Remember me
              </label>
            </div>
            </div>
            <div className="col-md-6 text-end">
            <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
            </div>
          </div>
          <div className="mt-0 text-start">
          
        </div>
        <div className="btn-login">
            <button type="submit" className="mt-3 btn btn-primary text-white w-100">Sign In</button>
          </div>

        </form>
        </div>
                    <div className="login_bottom">
        Don't have an account? <span  onClick={signUpButton}>Sign Up</span>
        <div className="mt-3 text-center g-btn">
        <GoogleLogin 
          onSuccess={credentialResponse => { debugger
           
            console.log(credentialResponse);
            
            console.log(credentialResponse.credential);
            const decoded = jwtDecode(credentialResponse.credential);
          console.log(decoded); 
          console.log('Name:'+decoded.name); 
          localStorage.setItem("username", decoded.name);
          localStorage.setItem("gEmailID", decoded.email);
          console.log('Email ID:'+decoded.email); 
          handleLogin();  // Call handleLogin here
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div> 
      </div>
                </div>
                <div class="form-container sign-in-container">
                <div className="logo" style={{top:'11px'}}>
                  <img src="https://lwfiles.mycourse.app/6500c6e26d2b1d1b30da7b5e-public/123e53dff6aa2371ee8ad523eea93c99.png" className="img-fluid" alt="" title="HYGIA"/>
                  </div>
                  <div className="loginbox">
                  
                  <div className="mb-3 mt-3 login-title">
          <h2 className="fs-6 mb-1">Welcome, Create your account</h2>
          <span>Enter your details</span>
        </div>
                <form onSubmit={handleSubmission}>
          <div class="form-group py-1">
            <div class="input-field position-relative">
              <span class="mdi mdi-account label-icon"></span>
              <input
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your name"
              />
            </div>
          </div>
          <div class="form-group py-1">
            <div class="input-field position-relative">
              <span class="mdi mdi-account label-icon"></span>
              <input
                type="text"
                id="usernames"
                className="form-control"
                value={usernames}
                onChange={handleUsernameChanges}
                placeholder="Enter your Username"
              />
            </div>
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
              <span className="mdi mdi-lock label-icon"></span>
              <input
                type={showPassword ? 'text' : 'password'}
                id="passwords"
                className={`form-control ${passwordTyping && !isPasswordValid() ? 'is-invalid' : ''} ps-4 pwd-info-icon`}
                value={passwords}
                onChange={(e) => { handlePasswordChanges(e); handlePasswordTyping(); }}
                onBlur={handlePasswordBlur}
                placeholder="Enter Password"
              />
              <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                <input
                  type="checkbox"
                  onChange={handleTogglePasswordVisibility}
                />
                <i className="mdi"></i>
              </label>
            </div>
            {passwordTyping && !isPasswordValid() && (
                <div className="text-danger mt-2">
                  Please ensure the password meets the following requirements:
                  <ul>
                    {!passwordStrength.length && <li>Minimum 8 characters</li>}
                    {!passwordStrength.uppercase && <li>At least one uppercase letter</li>}
                    {!passwordStrength.lowercase && <li>At least one lowercase letter</li>}
                    {!passwordStrength.number && <li>At least one number</li>}
                    {!passwordStrength.specialChar && <li>At least one special character</li>}
                  </ul>
                </div>
              )}
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
              <span className="mdi mdi-lock label-icon"></span>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                className="form-control"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm Password"
              />
              <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                <input
                  type="checkbox"
                  onChange={handleToggleConfirmPasswordVisibility}
                />
                <i className="mdi"></i>
              </label>
            </div>
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
              <span className="mdi mdi-lock label-icon"></span>
              <input
                type="date"
                className="form-control pe-2"
              />
            </div>
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
            <span className="mdi mdi-gender-male-female label-icon"></span>
                  <select
                    id="gender"
                    className="form-select "
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="" >Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
              
            </div>
          </div>
          {passwordError && <div className="text-danger">{passwordError}</div>}
        <div className="btn-login">
            <button type="submit" className={`mt-3 btn btn-primary text-white w-100 ${!passwordStrength.length ? 'disabled' : ''}"`}>Sign Up</button>
          </div>

        </form>
        </div>
                    <div className="login_bottom" style={{bottom:'25px'}}>
        <span  onClick={signInButton}>Sign in</span>
        </div>
                </div>
                <div className="or-line"></div>
                <div className="mt-3 pb-4 text-center g-btn">
        <GoogleLogin 
          onSuccess={credentialResponse => {
            console.log(credentialResponse);
            
            console.log(credentialResponse.credential);
            const decoded = jwtDecode(credentialResponse.credential);
          console.log(decoded); 
          //console.log('Name:'+decoded.name); 
          //console.log('Email ID:'+decoded.email); 
          localStorage.setItem('gEmailID', decoded.email);
          localStorage.setItem('gName', decoded.name);
            navigate('/google-sign-in'); 
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div>
                
                <div class="overlay-container">
                    <div class="overlay">
                        <div class="overlay-panel overlay-left">
                           <div class="left-content text-white">
                            <div class="fs-6 mb-2">HEALTHCARE SOLUTIONS</div>
                            <div class="fw-bolder" style={{fontSize:'3.2rem',lineHeight:'4rem',letterSpacing:'0.2rem'}}>
                              <span style={{color:'rgb(3, 169, 244)'}}>Connected</span> Care <br/>Healthy Future</div>
                              <div class="mt-5 fs-6">Delivering cutting-edge solutions to transform healthcare.</div></div>
                        </div>
                        <div class="overlay-panel overlay-right">
                        <div class="left-content text-white">
                            <div class="fs-6 mb-2">HEALTHCARE SOLUTIONS</div>
                            <div class="fw-bolder" style={{fontSize:'3.2rem',lineHeight:'4rem',letterSpacing:'0.2rem'}}>
                              <span style={{color:'rgb(3, 169, 244)'}}>Connected</span> Care <br/>Healthy Future</div>
                              <div class="mt-5 fs-6">Delivering cutting-edge solutions to transform healthcare.</div></div>
                        </div>
                    </div>
                </div>
            </div>






      <div className="container-fluid p-0" style={{display:'none'}}>
      <div className="row m-0">
        <div className="col-md-8 p-0">
          <div className="login-bg">
            
            <div className="left-content text-white">
              <div className="fs-6 mb-2">HEALTHCARE SOLUTIONS</div>
              <div className="fw-bolder" style={{fontSize: '3.2rem', lineHeight:'4rem',letterSpacing:'0.2rem'}}><span style={{color: 'rgb(3, 169, 244)'}}>Connected</span> Care <br/>Healthy Future</div>
              <div className="mt-5 fs-6">Delivering cutting-edge solutions to transform healthcare.</div>
              </div>
          
          </div>
        </div>
        <div className="col-md-4">
        
          <div className="p-4 login-content">
          <img src="https://lwfiles.mycourse.app/6500c6e26d2b1d1b30da7b5e-public/123e53dff6aa2371ee8ad523eea93c99.png" alt="" title="HYGIA"/>
        <div className="mb-4 mt-3 login-title">
          <h2 className="fs-5 mb-1">Welcome Back :)</h2>
          <span>Login to continue</span>
        </div>

        <form onSubmit={handleSubmit}>
          <div class="form-group py-1">
            <div class="input-field">
              <span class="mdi mdi-account label-icon"></span>
              <input
                type="text"
                className="form-control"
                value={username}
                onChange={handleUsernameChange}
                placeholder="Enter Username"
              />
            </div>
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
              <span className="mdi mdi-lock label-icon"></span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter Password"
              />
              <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                <input
                  type="checkbox"
                  onChange={() => setShowPassword(!showPassword)}
                />
                <i className="mdi"></i>
              </label>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="remember"/>
              <label class="form-check-label" for="remember">
              Remember me
              </label>
            </div>
            </div>
            <div className="col-md-6 text-end">
            <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
            </div>
          </div>
          <div className="mt-0 text-start">
          
        </div>
        <div className="btn-login">
            <button type="submit" className="mt-5 btn btn-primary text-white w-100">Sign In</button>
          </div>

        </form>
        <div className="login_bottom">
        Don't have an account? <Link to="/create-account" className="mt-5">Sign Up</Link>
        <div className="mt-3 text-center g-btn">
        <GoogleLogin 
          onSuccess={credentialResponse => { debugger
           
            console.log(credentialResponse);
            
            console.log(credentialResponse.credential);
            const decoded = jwtDecode(credentialResponse.credential);
          console.log(decoded); 
          console.log('Name:'+decoded.name); 
          localStorage.setItem("username", decoded.name);
          localStorage.setItem("gEmailID", decoded.email);
          console.log('Email ID:'+decoded.email); 
          handleLogin();  // Call handleLogin here
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div> 
      </div>
      </div>
      </div>
      </div>
      </div>
    <div className="login-container" style={{display:'none'}}>
      <div className="lgimg-box">
        <img src={logo} alt=""/>
      </div>
      <div className="login-box mt-5">
        <div className="mb-4 mt-3 login-title">
          <h2>Welcome Back :)</h2>
          <span>Login to continue</span>
        </div>

        <form onSubmit={handleSubmit}>
          <div class="form-group py-1">
            <div class="input-field">
              <span class="mdi mdi-account-outline p-2 label-icon"></span>
              <input
                type="text"
                id="username"
                className="form-control"
                value={username}
                onChange={handleUsernameChange}
                placeholder="Enter Username"
              />
            </div>
          </div>
          <div className="form-group py-1">
            <div className="input-field position-relative">
              <span className="mdi mdi-lock-outline p-2 label-icon"></span>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter Password"
              />
              <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                <input
                  type="checkbox"
                  onChange={() => setShowPassword(!showPassword)}
                />
                <i className="mdi"></i>
              </label>
            </div>
          </div>
          
          <div className="mt-0 text-start">
          <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
        </div>
          <div className="text-end">
            <button type="submit" className="mt-3 btn btn-success text-white">Login</button>
          </div>
        </form>
      </div>
      <div className="login_bottom">
        Don't have an account? <Link to="/create-account" className="mt-5">Sign Up</Link>
        <div className="mt-3 text-center g-btn">
        <GoogleLogin 
          onSuccess={credentialResponse => { debugger
           
            console.log(credentialResponse);
            
            console.log(credentialResponse.credential);
            const decoded = jwtDecode(credentialResponse.credential);
          console.log(decoded); 
          console.log('Name:'+decoded.name); 
          localStorage.setItem("username", decoded.name);
          localStorage.setItem("gEmailID", decoded.email);
          console.log('Email ID:'+decoded.email); 
          handleLogin();  // Call handleLogin here
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div> 
      </div>
    
    </div>
    </div>
  );
};

export default Login;
