import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import logo from './images/logo.png';
import './App.css';
import './settings.css';


const Settings = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({ description: "" });
  const [savedMessageVisible, setSavedMessageVisible] = useState(false);
  const [helpFunctions, setHelpFunctions] = useState([]);
  const { name: menuName } = useState([]);
 //const { name: menuName } = "get_meals_by_time";
  const [menuData, setMenuData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const [newMenuItem, setNewMenuItem] = useState({
    Menu: "",
    def: "",
  });
  const localStorageKey = `editedData_${menuName}`;
  const [currentlyEditedMealId, setCurrentlyEditedMealId] = useState(null);
  const [apiData, setApiData] = useState(null);
  const userSession = localStorage.getItem("User_session");
  const selectedPractice = localStorage.getItem("selectedPractice");
  const systemText = localStorage.getItem(`systemText_${userSession}`);

  const [showTextBox, setShowTextBox] = useState(false);
  const [textboxes, setTextboxes] = useState([
    // Initial textboxes
    { id: 0, text: '' },
  ]);
  const [menuButtonClicked, setMenuButtonClicked] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedNotificationMethod, setSelectedNotificationMethod] = useState('');
  const [newContact, setNewContact] = useState({
    Name: '',
    Phone_number: '',
    Email: ''
  });
  const [showAddNew,setShowAddNew] = useState(false);
  const [text, setText] = useState('');
  const [textareaHeight, setTextareaHeight] = useState('auto');
  
  const [grid, setGrid] = useState([[textboxes[0]]]);
  const isAdmin = user.name === "admin";
  //const [showAddRow, setShowAddRow] = useState(false);

  useEffect(() => {
    //const username = localStorage.getItem("username");
    const savedDescription = localStorage.getItem("description");
    const userSessionFromStorage = localStorage.getItem("User_session");
    const selectedPractice = localStorage.getItem("selectedPractice");
    const practiceSessionValue = selectedPractice || userSessionFromStorage;

    console.log("User Session:", userSessionFromStorage);
    console.log("Selected Practice:", selectedPractice);
    console.log("Practice Session:", practiceSessionValue);
    console.log("USer session from storage:", userSessionFromStorage);

    if (userSessionFromStorage) {
      fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${userSessionFromStorage}`, {
        headers: {
          "Practice-session": userSessionFromStorage,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${Response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Response Data:", data);
          const initialDescription = data.Response[1];
          console.log("Description:", initialDescription);
          setUser({
            name: data.Response[0], 
            description: savedDescription || initialDescription,
          });
          setUpdatedUser({ description: savedDescription || initialDescription });
          setHelpFunctions(data.Functions || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      console.error("userId not found in local storage");
    }
  }, []);

  // get data for menu
  const fetchMenuData = useCallback(() => {
    const psess = localStorage.getItem("practiceSession");
    fetch(`https://apps.hygiahealthservices.com/services/Get_data?name=${menuName}`, {
      headers: {
        "Practice-session": psess,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        console.log("MenuEdit API Response:", responseData);
  
        if (responseData.Response && Array.isArray(responseData.Response)) {
          setMenuData(responseData.Response);
          console.log("API Response:", responseData);
        } else {
          console.error("Invalid API response:", responseData);
          setMenuData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
        setMenuData([]);
      });
  }, [menuName]);

  useEffect(() => {
    fetchMenuData();
  }, [fetchMenuData, menuName]);
  
  useEffect(() => {
    const storedEditedData = JSON.parse(localStorage.getItem(localStorageKey));
    if (storedEditedData) {
      setEditedData(storedEditedData);
    }
  }, [localStorageKey]);

  // editing and saving the menu items
  useEffect(() => {
    const fetchData = async () => {
      let psessnew = '';
  
      const psess = localStorage.getItem("practiceSession");
  
      if (!psess) {
        psessnew = localStorage.getItem("User_session");
      } else {
        psessnew = localStorage.getItem("practiceSession");
      }
  
      const apiUrl = "https://apps.hygiahealthservices.com/services/menu_CRUD";
  
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Practice-session": psessnew,
          },
          body: JSON.stringify({
            method: "U",
            mealId: editedData.mealId,
            menuDetails: editedData.menuDetails,
            dietaryRestrictions: editedData.dietaryRestrictions,
          }),
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const responseData = await response.json();
        console.log("Menu updated successfully:", responseData);
  
        // Refetch menu data after successful save
        fetchMenuData();
      } catch (error) {
        console.error("Error updating menu:", error);
      } finally {
        setIsSaving(false);
      }
    };
  
    if (isSaving && editedData.mealId) {
      fetchData();
    }
  }, [isSaving, editedData, fetchMenuData]);

  useEffect(() => {
    console.log("Menu Data:", menuData);
  }, [menuData]);

  // system text log
  useEffect(() => {
      const userSession = localStorage.getItem("User_session");
  
      console.log("User_session ID:", userSession);
  
      if (userSession) {
        fetch(`https://apps.hygiahealthservices.com/services/Systemtext_log?id=${userSession}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            console.log("Log API Response:", data);
  
            if (data && data.Response && Array.isArray(data.Response)) {
              setLogData(data.Response.map((log) => ({
                id: log[0],
                datetime: log[1],
                systemText: log[2],
              })));
              console.log("Log Data", data);
            } else {
              console.error("Invalid or empty API response:", data);
              setLogData([]);
            }
          })
          .catch((error) => {
            console.error("Error fetching log data:", error);
            setLogData([]);
          });
      } else {
        console.error("userID not found in local storage");
      }
  }, []);

  // Notifications list
  useEffect(() => {
    // Fetch data from API
    fetch('https://apps.hygiahealthservices.com/services/get_list')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => {
        console.log(data); // Log the fetched data separately
        console.log("hi"); // Log the string "hi"
        if (Array.isArray(data.List)) {
          setListData(data.List);
          setLoading(false);
        } else {
          throw new Error('Data is not in expected format');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);  
  
  // user settings system text
  const handleEdit = () => {
    setIsEditing(true);
    setSavedMessageVisible(false);
    setNotificationMessage('');
  };

  // user setting save
  const handleSave = () => {
    const userSession = localStorage.getItem("User_session");

    const updatedSystemText = updatedUser.description;
    const trimmedText = updatedSystemText ? updatedSystemText.trim() : ''; // Added check here

    let psessnew = "";

    const psess = localStorage.getItem("practiceSession");

    if (!psess) {
        psessnew = localStorage.getItem("User_session");
    } else {
        psessnew = psess; 
    }
    if (trimmedText !== "") {
        if (trimmedText !== (user && user.description && user.description.trim())) { // Added check here
            fetch("https://apps.hygiahealthservices.com/services/Edit_Text", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Practice-session": psessnew,
                },
                body: JSON.stringify({
                    ID: userSession,
                    System_Text: trimmedText.replace(/\n/g, "<br>"),
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((responseData) => {
                    console.log("Server Response:", responseData);

                    // Check for a valid response structure
                    if (responseData && responseData.Response !== undefined) {
                        if (responseData.Response === true) {
                            // Update system text in the frontend
                            setIsEditing(false);
                            setUser((prevUser) => ({
                                ...prevUser,
                                description: trimmedText,
                            }));
                            localStorage.setItem("description", trimmedText);

                            // Trigger log update only if there are changes
                            updateLogData();

                            // Display saved message
                            setSavedMessageVisible(true);
                        } else {
                            console.error("Update failed");
                        }
                    } else {
                        console.error("Invalid API response structure");
                    }
                })
                .catch((error) => {
                    console.error("Error updating description:", error);
                });
        } else {
            // No changes made, so don't update the log
            setIsEditing(false);
            setUser((prevUser) => ({
                ...prevUser,
                description: trimmedText,
            }));
            localStorage.setItem("description", trimmedText);
            setSavedMessageVisible(true);
        }
    } else {
        // Display a message or alert indicating that System Text cannot be empty
        alert("System Text cannot be empty");
    }
};


  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  
  const handleInputChange = (e, index, fieldName) => {
    const newListData = [...listData]; // Create a copy of the listData array
    newListData[index] = {
      ...newListData[index], // Preserve other fields in the row
      [fieldName]: e.target.value, // Update the specified field in the row
    };
    setListData(newListData); // Update the listData state with the modified array
    setNotificationMessage(e.target.value);
    setText(e.target.value);
    setTextareaHeight(`${e.target.scrollHeight}px`);
  };

 // Options handle Edit
const handleFunctionEdit = (name, index) => {
  let psessnew = '';

  const psess = localStorage.getItem("practiceSession");

  if (!psess) {
    psessnew = localStorage.getItem("User_session");
  } else {
    psessnew = localStorage.getItem("practiceSession");
  }

  setShowAddNewItem(false);

  console.log("User_session:", localStorage.getItem("User_session"));
  console.log("Practice-session:", psessnew);

  console.log("handleFunctionEdit called");

  
  setApiData(null);

  fetch(`https://apps.hygiahealthservices.com/services/Get_data?name=${name}`, {
    headers: {
      "Practice-session": psessnew,
    },
  })
    .then((Response) => {
      console.log("API request initiated");
      if (!Response.ok) {
        throw new Error(`HTTP error! Status: ${Response.status}`);
      }
      return Response.json();
    })
    .then((responseData) => {
      console.log('API Response:', responseData);
      console.log("handleFunctionEdit API Response:", responseData);
      if (responseData.Response !== undefined && responseData.Response !== null) {
        const updatedFunctions = [...helpFunctions];
        updatedFunctions[index] = { ...updatedFunctions[index], apiData: responseData.Response };

        // Dynamic Table_name based on responseData
        const tableName = responseData.Table_name; // Assuming Table_name is present in responseData
        updatedFunctions[index].tableName = tableName;

        // Conditionally set the column names based on the response
        if (tableName) {
          const columnNames = responseData.Column_names; // Assuming Column_names is present in responseData
          updatedFunctions[index].columnNames = columnNames;
        } else {
          console.error("Table name not found in the API response.");
        }

        setHelpFunctions(updatedFunctions);
        setApiData(responseData); // Update the state after fetching new data
      } else {
        console.error("Invalid API response: Response is null or undefined");
      }
    })
    .catch((error) => {
      console.error("Error making API request:", error);
    });
  localStorage.setItem("tb_name", name);
};

  // option menu 
const handleEditInputChange = (e, mealId) => {
  const { name, value } = e.target;
  console.log("Name:", name, "Value:", value, "MealID:", mealId);

  setEditedData((prevData) => ({
    ...prevData,
    mealId: mealId,
    [name]: value,
  }));

  const apiUrl = "https://apps.hygiahealthservices.com/services/Functions_CRUD";
  const responseData = localStorage.getItem('tb_name');

  fetch(apiUrl, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          action_type: "update",
          table_name: responseData,
          columns: [{
            [name]: value
          }],          
          condition: [
              {
                  mealId: mealId,
              }
          ]
      }),
  })
  .then((response) => {
    console.log("Response status code:", response.status);
    return response.json()
  })
  .then((responseData) => {
      console.log("Menu item updated successfully:", responseData);

      // Assuming this function fetches the updated menu data from the server
      fetchMenuData();
  })
  .catch((error) => {
      console.error("Error updating menu item:", error);
  });
};

  //Option Delete
  const handleDeleteClick = (mealId) => {
    let psessnew = '';
    const psess = localStorage.getItem("practiceSession");

    if (!psess) {
        psessnew = localStorage.getItem("User_session");
    }
    else{
      psessnew = localStorage.getItem("practiceSession");
    }

    const apiUrl = "https://apps.hygiahealthservices.com/services/Functions_CRUD";
  
    console.log("Deleting menu item with mealId:", mealId);
    const responseData = localStorage.getItem('tb_name');

    const requestData = {
      action_type: "delete",
      table_name: responseData,
      condition: [
        {
          mealId: mealId
        }
      ]
    };
  
    console.log("Delete Item Request Data:", requestData);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Practice-session": psessnew,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        console.log("Response status code:", response.status);
        return response.json();
      })
      .then((responseData) => {
        console.log("Menu item deleted successfully:", responseData);
        // Additional operations if needed
      })
      .catch((error) => {
        console.error("Error deleting menu item:", error);
      });
  };

  //Options Add New Item
  const handleAddMenuItem = (e) => {
    e.preventDefault();
  console.log(localStorage.getItem('tb_name'));
    if (!newMenuItem.Menu || !newMenuItem.def) {
      alert("Please enter values for both.");
      setShowAddNewItem(true);
      return;
    }
    const responseData = localStorage.getItem('tb_name');
    const psess = localStorage.getItem("practiceSession");
    const apiUrl = "https://apps.hygiahealthservices.com/services/Functions_CRUD";
  
    const requestData = {
      action_type: "add",
      table_name: responseData,
      columns: [
      ...Object.entries(newMenuItem).map(([key, value]) => ({
        [key]: value
      }))
    ]
    };
  
    console.log("Add Item Request Data:", requestData);
  
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Practice-session": psess,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => { 
      console.log("Response status code:", response.status);
      return response.json()
      })
      .then((responseData) => {
        console.log("Menu item added successfully:", responseData);
        setNewMenuItem(Object.fromEntries(Object.keys(newMenuItem).map(key => [key, ""])));
        setShowAddNewItem(false);
      })
      .catch((error) => {
        console.error("Error adding menu item:", error);
      });
  };

  // add new item close 
  const handleCloseAddItem = () => {
    console.log("Closing add menu item popup");
    setNewMenuItem({
      Menu: "",
      def: "",
    });
  
    setShowAddNewItem(false);
  };  
  
  // Option add menu edit 
  const handleEditClick = (mealId) => {
    const currentItem = apiData.Response.find((item) => item[0] === mealId);
    const updatedData = {
      mealId,
      menuDetails: currentItem[1],
      dietaryRestrictions: currentItem[2],
    };
    setEditedData(updatedData);
    setCurrentlyEditedMealId(mealId);
    console.log("Menu edited",updatedData)    
  };

  //Option add menu save
  const handleSaveClick = async (mealId) => {
    try {
      setIsSaving(true);
      setCurrentlyEditedMealId(null);
  
      const psessnew = localStorage.getItem("practiceSession");
      const apiUrl = "https://apps.hygiahealthservices.com/services/menu_CRUD";
      console.log("Updating menu item with mealId:", mealId);
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Practice-session": psessnew,
        },
        body: JSON.stringify({
          method: "U",
          mealId: mealId,
          ...editedData 
        }),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      console.log("Menu updated successfully:", responseData);
      setEditedData({});
      setIsSaving(false);
      await fetchMenuData();
    } catch (error) {
      console.error("Error updating menu:", error);
      setIsSaving(false);
    }
  };
  // System text log
  const updateLogData = () => {
    const userSession = localStorage.getItem("User_session");
  
    if (userSession) {
      fetch(`https://apps.hygiahealthservices.com/services/Systemtext_log?id=${userSession}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Log API Response:", data);
  
          if (data && data.Response && Array.isArray(data.Response)) {
            setLogData(data.Response.map(log => ({
              id: log[0],
              datetime: log[1],
              systemText: log[2],
            })));
            console.log("Log Data", data);
          } else {
            console.error("Invalid or empty API response:", data);
            setLogData([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching log data:", error);
          setLogData([]);
        });
    } else {
      console.error("userID not found in local storage");
    }
  };

  setTimeout(() => {
    setSavedMessageVisible(false);
  }, 2000);

  const handleButtonClick = () => {
    setShowTextBox(true);
    setMenuButtonClicked(true);
    console.log('refreshed');
  };

  const handleCloseButtonClick = () => {
    const resetTextBoxes = textboxes.map(tb => ({ ...tb, text: '' }));
    setTextboxes(resetTextBoxes);
  
    const resetGrid = grid.map(row => row.map(col => ({ ...col, text: '' })));
    setGrid(resetGrid);
    setShowTextBox(false);
    setMenuButtonClicked(false);
  };  

  const handleSaveButtonClick = () => {
    setShowTextBox(false);
  };

  const handleArrowClick = (id, direction) => {
    console.log(`Arrow clicked: ID ${id}, Direction ${direction}`);
    const newTextBoxes = [...textboxes];
    const clickedTextBoxIndex = newTextBoxes.findIndex(textBox => textBox.id === id);
    const clickedTextBox = newTextBoxes[clickedTextBoxIndex];
  
    let newRow = clickedTextBox.row || 0;
    let newCol = clickedTextBox.col || 0;
  
    if (direction === 'right') {
      const rightid = document.getElementById('menu_'+id);
      rightid.classList.add('menuhide');
      newCol++;
    } else if (direction === 'down') {
      const rightid = document.getElementById('menu_'+id);
      rightid.classList.add('menuhide');
      newRow++;
    }
  
    const newTextBox = { id: newTextBoxes.length + 1, text: '', row: newRow, col: newCol };
  
    // Find the index of the next textbox in the same column
    const nextInColumnIndex = newTextBoxes.findIndex(
      textBox => textBox.col === newCol && textBox.row > newRow
    );
  
    // Insert the new textbox below the clicked textbox or at the end if there's no next textbox in the same column
    if (nextInColumnIndex !== -1) {
      newTextBoxes.splice(nextInColumnIndex, 0, newTextBox);
    } else {
      newTextBoxes.splice(clickedTextBoxIndex + 1, 0, newTextBox);
    }
  
    const newGrid = [...grid];
    if (!newGrid[newRow]) {
      newGrid[newRow] = [];
    }
    newGrid[newRow][newCol] = newTextBox;
  
    setGrid(newGrid);
    setTextboxes(newTextBoxes);
  };
  
  const handleChange = (e, id) => {
    const newText = e.target.value;
    const newTextBoxes = textboxes.map((tb) =>
      tb.id === parseInt(id) ? { ...tb, text: newText } : tb
    );
    setTextboxes(newTextBoxes);

    const [row, col] = findPosition(id);
    const newGrid = [...grid];
    newGrid[row][col].text = newText;
    setGrid(newGrid);
    console.log(`Textbox with ID ${id} updated with text: ${newText}`);
  };

  const findPosition = (id) => {
    for (let row = 0; row < grid.length; row++) {
      for (let col = 0; col < grid[row].length; col++) {
        if (grid[row][col] && grid[row][col].id === id) {
          return [row, col];
        }
      }
    }
    return [-1, -1];
  };

  //Notifications
  const handleRowSelect = (rowId) => {
    const isSelected = selectedRows.includes(rowId);
    if (isSelected) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

//Notifications AddNew
  const handleAddNew = () => {
    setShowAddNew(!showAddNew);

    // Send a POST request to add the contact to the server
    fetch('https://apps.hygiahealthservices.com/services/add_contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newContact),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to add new contact');
      }
      return response.json();
    })
    .then(data => {
      console.log('New contact added:', data);
    })
    .catch(error => {
      console.error('Error adding new contact:', error);
    });
  };

  const handleAddNotification = (e) => {
    e.preventDefault();
  
    // Check if all fields are filled
    if (!newContact.Name || !newContact.Phone_number || !newContact.Email) {
      alert("Please enter values for all fields.");
      setShowAddNew(true);
      return;
    }
  
    // Add country code +91 to Phone_number
    const phoneNumberWithCountryCode = `+${newContact.Phone_number}`;
  
    // Prepare the request data
    const apiUrl = "https://apps.hygiahealthservices.com/services/add_contact";
    const requestData = {
      Name: newContact.Name,
      Phone_number: phoneNumberWithCountryCode,
      Email: newContact.Email
    };
  
    // Log request data for debugging
    console.log("Detail Request Data:", requestData);
  
    // Send the POST request to the server
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
    
    .then((Response) => {
      if (!Response.ok) {
        throw new Error('Failed to add new contact');
        
      }
       // Fetch data from API
    fetch('https://apps.hygiahealthservices.com/services/get_list')
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
    .then(data => {
      console.log(data); // Log the fetched data separately
      console.log("hi"); // Log the string "hi"
      if (Array.isArray(data.List)) {
        setListData(data.List);
        setLoading(false);
        setShowAddNew(false);
      } else {
        throw new Error('Data is not in expected format');
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
      return Response.json();


    })
    .then((responseData) => {
     console.log("Response:", responseData);
    if (!responseData.success) {
    throw new Error(responseData.Response || 'Failed to add new contact');
    
    }

   
  
      console.log("Details added successfully:", responseData);
  
      // Add the new contact to the listData array
      const updatedListData = [...listData, {
        id: responseData.id,
        Name: newContact.Name,
        Phone_number: newContact.Phone_number,
        Email: newContact.Email
      }];
  
      // Update the state to re-render the component with the updated listData
      setListData(updatedListData);
  
      // Clear the newContact object
      setNewContact({ Name: "", Phone_number: "", Email: "" });
  
      // Close the add new form
      setShowAddNew(false);
    })
    .catch((error) => {
      // Log and handle errors
      console.error("Error adding new contact:", error);
      alert("Failed to add new contact: " + error.message); // Show error message to user
    });
    
  };

  const handleCloseNotification = () => {
    console.log("Closing the details");
    setNewContact({
      Name: "",
      Phone_number: "",
      Email: "",
    });
  
    setShowAddNew(false);
  }; 

    // Notification button only one at a time
  // const handleNotify = () => {
  //   // Validate selected notification method
  //   if (!selectedNotificationMethod) {
  //     console.error('No notification method selected');
  //     return;
  //   }

  //   // Log notification message, selected notification type, and selected list to console
  //   console.log("Notification message:", notificationMessage);
  //   console.log("Selected notification method:", selectedNotificationMethod);
  //   console.log("Selected rows:", selectedRows);

  //   // Prepare notification type based on selected notification method
  //   let notificationType;
  //   switch (selectedNotificationMethod) {
  //     case 'Call':
  //       notificationType = 'C';
  //       break;
  //     case 'SMS':
  //       notificationType = 'S';
  //       break;
  //     case 'Email':
  //       notificationType = 'E';
  //       break;
  //     default:
  //       console.error('Invalid notification method');
  //       return;
  //   }

  //   // Prepare notification payload
  //   const payload = {
  //     id: selectedRows,
  //     message: notificationMessage,
  //     notification_type: notificationType
  //   };

  //   // Send notification to API
  //   fetch('https://apps.hygiahealthservices.com/services/send_notification', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(payload)
  //   })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error('Failed to send notification');
  //     }
  //     return response.json();
  //   })
  //   .then(data => {
  //     console.log('Notification sent:', data);
  //     setSavedMessageVisible(true);
  //   })
  //   .catch(error => {
  //     console.error('Error sending notification:', error);
  //   });
  // };

  // notification button
  
  // Notification button all three at a time
  
  
  const handleNotify = async () => {
    // Define the notification methods in the desired sequence
    const notificationMethods = ['Call', 'SMS', 'Email'];
  
    // Function to send notification for a single method
    const sendNotification = async (method) => {
      // Prepare notification payload for the current method
      const payload = {
        id: selectedRows,
        message: notificationMessage,
        notification_type: method.charAt(0).toUpperCase(), // Assuming 'Call' => 'C', 'SMS' => 'S', 'Email' => 'E'
      };
  
      try {
        // Send notification to API
        const response = await fetch('https://apps.hygiahealthservices.com/services/send_notification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error(`Failed to send ${method} notification`);
        }
  
        console.log(`${method} notification sent`);
  
        // If there are more notification methods, proceed to the next one
        const nextIndex = notificationMethods.indexOf(method) + 1;
        if (nextIndex < notificationMethods.length) {
          const nextMethod = notificationMethods[nextIndex];
          await sendNotification(nextMethod);
        } else {
          // All notifications sent
          console.log('All notifications sent');
          setSavedMessageVisible(true);
        }
      } catch (error) {
        console.error(`Error sending ${method} notification:`, error);
      }
    };
  
    // Start sending notifications from the first method
    await sendNotification(notificationMethods[0]);
  };

  const handleSelectMessage = (selectedMessage) => {
    setNotificationMessage(selectedMessage);
  };
  

  return (
    <div>
      <div className="login-container">
      <div className="lgimg-box">
        <img src={logo} alt="" />
        <p className="pt-2 text-white">
        <i>Welcome <strong>{user.name}</strong>
        {selectedPractice && (
          <>
            <br />
            Practice: <strong>{selectedPractice}</strong>
          </>
        )}
        </i></p>
      </div>

      <ul className="nav nav-tabs" id="chhc-tabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active btn me-2"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#chat"
            type="button"
            role="tab"
            aria-controls="chat"
            aria-selected="true"
          >
            User Settings
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link btn"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#result"
            type="button"
            role="tab"
            aria-controls="result"
            aria-selected="false"
          >
            Options
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link btn"
            id="log-content"
            data-bs-toggle="tab"
            data-bs-target="#log"
            type="button"
            role="tab"
            aria-controls="log"
            aria-selected="false"
          >
            Log
          </button>
        </li>
        {isAdmin && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link btn"
                id="information-tab"
                data-bs-toggle="tab"
                data-bs-target="#info"
                type="button"
                role="tab"
                aria-controls="info"
                aria-selected="false"
              >
                Notifications
              </button>
            </li>
          )}
      </ul>

      <div className="tab-content" id="myTabContent">
      <div className="tab-pane fade show active" id="chat" role="tabpanel" aria-labelledby="chat-tab">
  {loading ? (
    <p>Loading...</p>
  ) : (
    <div className="setting-msg-box">
      {isEditing ? (
        <div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              System Text: {systemText !== 'null' ? systemText : ''}
            </label>
            <textarea
    className="form-control"
    id="description"
    name="description"
    value={updatedUser && updatedUser.description ? updatedUser.description.replace(/<br>/g, '\n') : ''}
    onChange={handleInputChanges}
    style={{ minHeight: '215px', maxHeight: '215px', overflow: 'auto' }}
/>

          </div>
          <div className="text-end">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSave}
            >
              <span className="mdi mdi-content-save"></span> Save
            </button>
          </div>
          {savedMessageVisible && (
            <p className="text-success mt-2">Saved</p>
          )}
        </div>
      ) : (
        <div className="setting-msg">
          <p>
            <strong>System Text:</strong>
            <span
    dangerouslySetInnerHTML={{
        __html: user && user.description ? user.description.replace(/\n/g, '<br>') : ''
    }}
/>
          </p>
          <div className="text-end">
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleEdit}
            >
              <span className="mdi mdi-file-edit-outline"></span> Edit System Text
            </button>
          </div>
        </div>
      )}
    </div>
  )}
      </div>
      <div className="tab-pane" id="result" role="tabpanel" aria-labelledby="chat-tab">
        <div className="setting-msg-box">
          <div className="help-functions-table">
            <h2>Options</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {helpFunctions
                  .filter(func => func[4])
                  .map((func, index) => (
                    <tr key={index}>
                      <td>{func[1]}</td>
                      <td>
                        {func[4] && (
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm rounded-circle"
                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                            onClick={() => handleFunctionEdit(func[0], index)}
                          >
                            <span className="mdi mdi-square-edit-outline"></span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="tab-pane" id="log" role="tabpanel" aria-labelledby="log-tab">
        {/* Content for "Logs" tab */}
        <div className="setting-msg-box">
          <div className="log-table">
            <h2>Logs</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{display: 'none'}}>ID</th>
                  <th>Date & time</th>
                  <th>System Text</th>
                </tr>
              </thead>
              <tbody>
                {logData.map((log) => (
                  <tr key={log.id}>
                    <td style={{display: 'none'}}>{log.id}</td>
                    <td>{log.datetime}</td>
                    {/* <td>{log.systemText}</td> */}
                    <td dangerouslySetInnerHTML={{ __html: log && log.systemText ? log.systemText.replace(/\n/g, '<br>') : '' }}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="tab-pane" id="info" role="tabpanel" aria-labelledby="information-tab">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="setting-msg-box">
          <div className="row">
            <div className="mb-3 col-md-6">
              <label htmlFor="des" className="form-label">
                Notification Message:
              </label>
              <textarea
                className="form-control"
                id="des"
                name="des"
                value={notificationMessage}
                onChange={handleInputChange}
                style={{ minHeight: '100px', maxHeight: '100px', overflowY: 'auto' }}
              />
            </div>
            <div className="mb-3 col-md-6">
        <label htmlFor="notificationSelect" className="form-label">Select Notification Message:</label>
        <select
          className="form-select"
          id="notificationSelect"
          onChange={(e) => handleSelectMessage(e.target.value)}
        >
          <option value="">Select a message</option>
          <option value="Welcome to Hygia! To download the HYGIA app, please visit the App Store or Google Play Store on your mobile device. Once downloaded, open the app and follow the on-screen instructions to set up your account.">Welcome to Download Hygia</option>
          <option value="Congratulations on choosing our app to access top-notch healthcare services anytime, anywhere!  Whether you're seeking medical advice, scheduling appointments, or managing your health records, we've got you covered.  Your well-being is our priority, and we're here to support you every step of the way on your healthcare journey.  Let's work together to keep you healthy and thriving">Welcome to use Hygia</option>
          <option value="Oops! We noticed that your app download didn't quite go as planned. Don't worry, we're here to help! Our team is dedicated to ensuring a smooth experience, and we'd love to assist you in any way we can. Please reach out to our support team for assistance, and let's get you on track to accessing all the amazing features of our app!  Thank you for your patience and understanding.">Failure on usage Hygia App</option>
        </select>
      </div>
          </div>
          <div className="row">
          <div className="col-md-12">
          <div className="mb-2">
          <div class="form-check form-check-inline">
          <input
            type="checkbox"
            onChange={() => setSelectedNotificationMethod('Call')}
            class="form-check-input"  id="Call"
          />
          <label class="form-check-label" for="Call">Call</label>
          
        </div>
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            onChange={() => setSelectedNotificationMethod('SMS')}
            class="form-check-input"  id="SMS"
          />
          <label class="form-check-label" for="SMS">Text Message SMS</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            onChange={() => setSelectedNotificationMethod('Email')}
            class="form-check-input"  id="Email"
          />
          <label class="form-check-label" for="Email">Email</label>
        </div>
      </div>
<div class="row mb-2">
  <div className="col-auto">
  <h5>List of Numbers/Email ID's</h5>
  </div>
</div>
<div className="table-responsive" style={{maxHeight:'45vh',overflowY:'auto'}}>
      <table className="table table-bordered">
        <thead className="table-light">
          <tr>
            <th>Checkbox</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            {/* <th><button type="button" onClick={handleAddNew} className="btn btn-primary btn-sm"><i className="mdi mdi-plus"></i> Add New</button></th> */}
            <th>
            <div className="d-flex justify-content-between">
            {!showAddNew && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm "
                      onClick={handleAddNew}
                    >
                    <i className="mdi mdi-plus"></i>
                    Add New
                    </button>
                    )}
                    <button
          type="button"
          className="btn btn-success btn-sm"
          disabled={selectedRows.length === 0 || !selectedNotificationMethod}
          onClick={handleNotify}
        >
        <i className="mdi mdi-send"></i>  Notify
        </button>
            </div> 
            </th>
          </tr>
        </thead>
        <tbody>
        {showAddNew && (
                    <tr>
                    <td>
                        <input
                          type="checkbox"
                          onChange={() => handleRowSelect([0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={newContact.Name}
                          onChange={(e) =>
                            setNewContact({
                              ...newContact,
                              Name: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        className="form-control"
                          value={newContact.Phone_number}
                          onChange={(e) =>
                            setNewContact({
                              ...newContact,
                              Phone_number: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        className="form-control"
                          value={newContact.Email}
                          onChange={(e) =>
                            setNewContact({
                              ...newContact,
                              Email: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                      <button
                          type="button"
                          className="btn btn-primary btn-sm rounded-circle me-1"
                          onClick={handleAddNotification} title="AddDetail"
                        >
                        <span class="mdi mdi-plus"></span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm rounded-circle"
                          onClick={handleCloseNotification} title="Close"
                        >
                        <span className="mdi mdi-close"></span>
                        </button>
                      </td>
                    </tr>
                        )}
          {listData.map((row, index) => (
            <tr key={index}>
              <td><input type="checkbox" onChange={() => handleRowSelect(row[0])} /></td>
              <td class="Hi">{row[1]}</td>
              <td>{row[2]}</td>
              <td>{row[3]}</td>
              <td></td>
            </tr>
          ))} 
        </tbody>
      </table>
      </div>
      <div className="text-end">
        <button
          type="button"
          className="btn btn-success mt-2 btn-sm"
          disabled={selectedRows.length === 0 || !selectedNotificationMethod}
          onClick={handleNotify}
        >
        <i className="mdi mdi-send"></i>  Notify
        </button>
      </div>
          </div>
          </div>
        </div>
      )}
    </div>
      </div>
      <div className="btn-back">
        <Link to="/main-page" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    </div>
    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered">
    <div className="modal-content p-0 rounded-0 border-0" style={{ maxWidth:'100%'}}>
      <div className="modal-header">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="menu-container"  style={{ maxHeight: '380px', overflowY: 'auto' }}>
 {apiData && apiData.Response && apiData.Response[0] && apiData.Response[0].map && (
  <div>
    <table className="table table-bordered m-0">
      <thead>
      <tr>
          {apiData.Response[0].map((heading, index) => {
            if (index !== 0) {
              return <th key={index}>{heading}</th>;
            }
            return null; 
          })}
          <th>{!showAddNewItem && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm "
                      // onClick={() => setShowAddNewItem(true)}
                      onClick={() => {
                      setNewMenuItem({
                        Menu: "",
                        def: "",
                      });
                      setShowAddNewItem(true);
                    }}
                    >
                      <span class="mdi mdi-plus"></span> Add New Item
                    </button>
                  )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                  {showAddNewItem && (
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={newMenuItem.Menu}
                          onChange={(e) =>
                            setNewMenuItem({
                              ...newMenuItem,
                              Menu: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        className="form-control"
                          value={newMenuItem.def}
                          onChange={(e) =>
                            setNewMenuItem({
                              ...newMenuItem,
                              def: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm rounded-circle me-1"
                          onClick={handleAddMenuItem} title="Add"
                        >
                        <span class="mdi mdi-plus"></span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm rounded-circle"
                          onClick={handleCloseAddItem} title="Close"
                        >
                        <span className="mdi mdi-close"></span>
                        </button>
                      </td>
                    </tr>
                        )}
                        {apiData.Response.slice(1).map((item) => (
                          <tr key={item[0]}>
                            <td>
                              {currentlyEditedMealId === item[0] ? (
                                <input
                          type="text"
                          className="form-control"
                          value={editedData.menuDetails !== undefined ? editedData.menuDetails : item[1]}
                          name="menuDetails"
                          onChange={(e) => handleEditInputChange(e, item[0])}
                        />
                              ) : (
                                item[1]
                              )}
                            </td>
                            <td>
                              {currentlyEditedMealId === item[0] ? (
                                <input
                          type="text"
                          className="form-control"
                          value={editedData.dietaryRestrictions !== undefined ? editedData.dietaryRestrictions : item[2]}
                          name="dietaryRestrictions"
                          onChange={(e) => handleEditInputChange(e, item[0])}
                        />
                              ) : (
                                item[2]
                              )}
                            </td>
                            <td>
                              {currentlyEditedMealId === item[0] ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success btn-sm rounded-circle me-1"
                                  onClick={() => handleSaveClick(item[0])}
                                >
                                <span className="mdi mdi-content-save"></span>
                                  
                                </button>
                              ) : (
                                <button className="btn btn-outline-primary btn-sm rounded-circle me-1"
                                type="button"
                                  onClick={() => handleEditClick(item[0])}
                                >
                                <span class="mdi mdi-square-edit-outline fs-6"></span>
                                </button>
                              )}
                              <button  type="button" className="btn btn-outline-danger btn-sm rounded-circle" onClick={() => handleDeleteClick(item[0])}>
                              <span class="mdi mdi-trash-can fs-6"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                          </tbody>
                              </table>
                            </div>
                          )} 
                          <div style={{ marginLeft: '10px' }}>
      <div>
        <button onClick={handleButtonClick} className={showTextBox ? 'hidden btn btn-primary my-menu' : 'click-me btn btn-primary my-menu'}>
          What is on my menu?
        </button>
      </div>
      {showTextBox && (
        <div style={{ display: 'inline-block', textAlign: 'left', marginTop: '10px',marginLeft:'20px' }}>
          {grid.map((row, rowIndex) => (
            <div key={rowIndex} style={{ marginBottom: '30px',whiteSpace:'nowrap' }}>
              {row.map((col, colIndex) => (
                <div key={colIndex} id={'menu_' + col.id} style={{ display: 'inline-block', marginRight: '35px', position: 'relative',verticalAlign:'top' }}>
                <div className="box-input" style={{ position: 'relative' }}>
                  <textarea
                    rows="2"
                    cols="20"
                    value={col.text}
                    onChange={(e) => handleChange(e, col.id)}
                    data-id={col.id}
                    className="form-control"
                  ></textarea>
                  <div class="icon-arrow arrow-right" onClick={() => handleArrowClick(col.id, 'right')}></div>
                  <div class="icon-arrow arrow-down" onClick={() => handleArrowClick(col.id, 'down')}></div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
</div>
  <div className="float-end mt-4">
    <button className="btn btn-success " onClick={handleSaveButtonClick}><i className="mdi mdi-content-save"></i> Save</button>
  </div>
</div>                           
    <div className="modal-footer">
        <button 
            type="button" 
            className="btn btn-outline-danger btn-sm" 
            data-bs-dismiss="modal"
            onClick={handleCloseButtonClick}
        >
        <span class="mdi mdi-close"></span> Close</button>
    </div>
 </div>
  </div>
  </div>
  </div>
);
};

export default Settings;