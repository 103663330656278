import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import CreateAccount from './CreateAccount';
import ChatComponent from './ChatComponent';
import MainPage from './MainPage';
import DataTable from './DataTable';
import Settings from './settings';
import APIDataDisplay from "./APIDataDisplay";
import AvatarPage from "./AvatarPage"; 
import ChatSpeech from './ChatSpeech';
import ChatFunctions from './ChatFunctions';
import MenuEdit from './MenuEdit';
import ForgotPassword from './ForgotPassword';
import { GoogleLogin } from '@react-oauth/google';
import GoogleSignInPage from './GoogleSignInPage';


const GoogleLoginComponent = () => {
  // const responseMessage = (response) => {
  //   console.log(response);
  // };

  // const errorMessage = (error) => {
  //   console.log(error);
  // };

  return (
    <div>
      <h2>React Google Login</h2>
      <br />
      <br />
      <GoogleLogin clientId="1096835915922-2d08ral04uae5danerejnubnvhulr528.apps.googleusercontent.com">
      <App />
        </GoogleLogin>
      {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/chat-component" element={<ChatComponent />} />
        <Route path="/create-account" element={<CreateAccount />} />
        {/* <Route path="/main-page/:practiceCode" element={<MainPage />} /> */}
        <Route exact path="/" element={<Login />} />
        <Route path="/main-page" element={<MainPage />} />
        {/* <Route path="/practice_codes" element={<PracticeCodes />} /> */}
        <Route path="/datatable" element={<DataTable />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/login" element={<Login />} />
        <Route path="/apidatadisplay" element={<APIDataDisplay />} />
        <Route path="/avatarpage" element={<AvatarPage />} />
        <Route path="/chatspeech" element={<ChatSpeech />} />
        <Route path="/chatfunctions" element={<ChatFunctions />} />
        <Route path="/menu-edit/:menuName" element={<MenuEdit />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/google-login" element={<GoogleLoginComponent />} />
        <Route path="/google-sign-in" element={<GoogleSignInPage />} />
      </Routes>
    </Router>
  );
};

export default App;
