import React, { useState, useEffect, useCallback, useMemo } from "react";
import './ChatComponent.css';
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
import { detect } from 'lang-js';

const ChatComponent = () => {
  const ID = localStorage.getItem("ID");
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState({});
  const [isRecording, setIsRecording] = useState(false);
  const mode = "C";
  const securityKey = "79d0ca9e-5d22-11ee-8c99-0242ac120002";
  const selectedPractice = localStorage.getItem("selectedPractice");
  const userSession = localStorage.getItem("User_session");

  const appendMessage = (role, content) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: role, content: content },
    ]);
  };

  const getlitem = localStorage.getItem("User_session");

  console.log("getlitem:",getlitem);

  const fetchInitialChatData = useCallback(() => {
    const session = selectedPractice || userSession;
    const sessionHeader = selectedPractice ? "Practice-session" : "User_session";
    const sessionValue = selectedPractice ? localStorage.getItem("practiceSession") : userSession;
  
    if (!sessionValue) {
      console.error("Session value not available");
      return;
    }
  
    const url = `https://apps.hygiahealthservices.com/services/Hygia_chat?id=${session}`;
  
    console.log("Fetching initial chat data. Session:", session);
  
    const headers = {
      [sessionHeader]: sessionValue,
      "Security-Key": securityKey,
    };
  
    fetch(url, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Initial chat data response:", data);
        if (data.messages && Array.isArray(data.messages)) {
          const filteredMessages = data.messages.filter(
            (message) => message.role !== "function" && message.role !== "system"
          );
  
          setMessages((prevMessages) => [
            ...prevMessages,
            ...filteredMessages,
          ]);
        } else {
          console.error("Invalid initial chat data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching initial chat data:", error);
      });
  }, [userSession, selectedPractice, securityKey]);
  
  const speakResponse = (response) => {
    console.log("Speaking response:", response);
    const utterance = new SpeechSynthesisUtterance(response);
    speechSynthesis.speak(utterance);
  };

  const detectLanguage = (text) => {
    try {
      return detect(text);
    } catch (error) {
      console.error('Error detecting language:', error);
      return 'en';
    }
  };
  
  const sendMessage = useCallback(() => {
    console.log('sendMessage function called');
    if (!isRecording) {
    const userMessage = userInput.trim();
    const detectedLanguage = detectLanguage(userMessage);
    if (detectedLanguage === 'en' && userMessage !== "") {
      setUserInput("");
  
      appendMessage("user", userMessage);
  
      const sessionValue = selectedPractice ? localStorage.getItem("practiceSession") : getlitem;
  
      const headers = {
        "Practice-session": sessionValue,
        "Security-Key": securityKey,
        "Content-Type": "application/json",
      };
  
      console.log("Sending message. Session:", sessionValue);
      console.log("Request Headers:", headers);
      console.log("Request Body:", { Text: userMessage, Patient_ID: ID, Mode: mode });
  
      fetch(`https://apps.hygiahealthservices.com/services/Hygia_message`, {
        method: "POST",
        body: JSON.stringify({ Text: userMessage, Patient_ID: ID, Mode: mode }),
        headers: headers,
      })
        .then((response) => {
          console.log("Message sent. Response Status:", response.status);
          return response.json();
        })
        .then((data) => {
          console.log("Response from server:", data);
          const assistantResponse = data.Response;
          appendMessage("assistant", assistantResponse);
          speakResponse(assistantResponse);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          if (error.response) {
            console.error("Response Data:", error.response.data);
          }
        });
    } else {
      console.log("Non-English message detected. Ignoring.");
    }
  }
  }, [userInput, ID, getlitem, mode, securityKey, selectedPractice,isRecording]);

const clearChat = useCallback(() => {
  const userSession = localStorage.getItem("User_session");
  console.log("Clear Chat User_session:",userSession);
  const url = `https://apps.hygiahealthservices.com/services/clear-chat?id=${userSession}`;

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Chat cleared successfully.");
      console.log("Chat clear:", data);
      setMessages([]);
    })
    .catch((error) => {
      console.error("Error clearing chat:", error);
    });
    localStorage.removeItem("storedChatData");
}, [setMessages]);

const handleKeyDown = (event) => {
  console.log('handleKeyDown triggered');
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    if (!isRecording) {
      const trimmedInput = userInput.trim();
      if (trimmedInput !== "") {
        sendMessage();
      }
    }
  } else if (event.key === "Enter" && event.shiftKey) {
    // Allow new line on Shift + Enter
    event.preventDefault();
    setUserInput((prev) => prev + "\n");
  }
};

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter" && event.shiftKey) {
  //     // Allow new line on Shift + Enter
  //     setUserInput((prev) => prev + "\n");
  //   }
  // };
  

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     event.preventDefault();
  //     if (!isRecording) {
  //       const trimmedInput = userInput.trim();
  //       if (trimmedInput !== "") {
  //         sendMessage();
  //       }
  //     }
  //   }
  // };
  
  // const handleKeyUp = (event) => {
  //   if (event.key === "Enter" && event.shiftKey) {
  //     event.preventDefault();
  //     setUserInput((prev) => prev + "\n");
  //   }
  // };

  const recognition = useMemo(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recog = new SpeechRecognition();
    recog.continuous = true;
    recog.lang = 'en-US';
    return recog;
  }, []);

  const handleStartRecognition = () => {
    if (!isRecording) {
      setIsRecording(!isRecording);
    }
  };

  useEffect(() => {
    const startRecording = () => {
      setIsRecording(true);
      recognition.start();
    };
  
    const stopRecording = () => {
      setIsRecording(false);
      recognition.stop();
    };
  
    recognition.onresult = (event) => {
      const recognizedText = event.results[event.results.length - 1][0].transcript;
      setUserInput(recognizedText);
    };
  
    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };
  
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  
    // Cleanup the recognition on component unmount
    return () => {
      stopRecording();
    };
  }, [isRecording, recognition]);

//   useEffect(() => {
//   const ID = localStorage.getItem("ID");

//   if (ID) {
//     fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${ID}`)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then((userData) => {
//         console.log("User Data from API:", userData);

//         const usernameFromAPI = userData.Response && userData.Response[0];

//         if (usernameFromAPI) {
//           setUser({
//             name: usernameFromAPI,
//           });
//         } else {
//           console.error("Username not found in the response");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   } else {
//     console.error("userId not found in local storage");
//   }
// }, []);

useEffect(() => {
  const userSession = localStorage.getItem("User_session");

  if (userSession) {
    fetch(`https://apps.hygiahealthservices.com/services/Get_details?id=${userSession}`, {
      headers: {
        'User_session': userSession,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((userData) => {
        console.log("Get_details API Response:", userData);

        const usernameFromAPI = userData.Response && userData.Response[0];

        if (usernameFromAPI) {
          setUser({
            name: usernameFromAPI,
          });
        } else {
          console.error("Username not found in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  } else {
    console.error("User session not found in local storage");
  }
}, []);

useEffect(() => {
  const handleSendMessage = (event) => {
    event.preventDefault();
    sendMessage();
  };

  const handleClearChat = () => {
    clearChat();
  };

  const sendButton = document.getElementById("chat-form");
  sendButton.addEventListener("submit", handleSendMessage);

  const clearButton = document.getElementById("clear-button");
  clearButton.addEventListener("click", handleClearChat);

  // const startRecognitionButton = document.getElementById("startRecognition");
  // startRecognitionButton.addEventListener("click", handleStartRecognition);

  // Cleanup the event listeners on component unmount
  return () => {
    sendButton.removeEventListener("submit", handleSendMessage);
    clearButton.removeEventListener("click", handleClearChat);
    //startRecognitionButton.removeEventListener("click", handleStartRecognition);
  };
}, [sendMessage, clearChat]);
  
  useEffect(() => {
    const fetchAndClearChat = async () => {
      localStorage.removeItem("storedChatData");
      await fetchInitialChatData(); // Fetch initial chat data
      // clearChat(); // Clear chat after fetching data
    };
  
    fetchAndClearChat();
  
    // Cleanup: Clear chat when component is unmounted
    return () => {
      clearChat();
    };
  }, [fetchInitialChatData, clearChat, userSession]);

  useEffect(() => {
    const chatContainer = document.getElementById("chat-container");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [messages]);

  const handleSubmit = (event) => {
    console.log('handleSubmit triggered');
    event.preventDefault();
    if (userInput.trim() !== "") {
      sendMessage();
    }
  };

  return (
    <div className="login-container chat-page">
      <div className="lgimg-box">
         <img src={logo} alt=""/>
         <p className="pt-2 text-white">
         <i>Welcome <strong>{user.name}</strong>
        {selectedPractice && (
          <>
            <br />
            Practice: <strong>{selectedPractice}</strong>
          </>
        )}
        </i></p>
       </div>
       <div id="chat-container" className="messages-container">
  {messages.map((message, index) => (
    <div key={index} className={`message ${message.role}`}>
      <span className={`prefix prefix-${message.role}`}>
        {message.role.charAt(0).toUpperCase() + message.role.slice(1)}:
      </span>{" "}
      {message.content.split('\n').map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {lineIndex > 0 && <br />} {line}
        </React.Fragment>
      ))}
    </div>
  ))}
</div>
      <div id="input-container">
      <form id="chat-form" onSubmit={handleSubmit}>
  <textarea
    id="user-input"
    placeholder="Type your question here... "
    value={userInput}
    onChange={(e) => setUserInput(e.target.value)}
    onKeyDown={handleKeyDown}
  />
  <button type="submit" id="send-button" title="Send" className="send-button">
    <span className="mdi mdi-send"></span>
  </button>
  <button
    id="clear-button"
    title="Clear Chat"
    className="btn btn-secondary clear-button"
    onClick={clearChat}
    type="button"
  >
    <span className="mdi mdi-eraser"></span>
  </button>
  <button
    className="btn btn-outline-danger rounded-circle"
    id="startRecognition"
    title="Record"
    style={{ height: 42, marginLeft: 4 }}
    onClick={handleStartRecognition}
    type="button"
  >
    <span
      className={`mdi ${
        isRecording ? 'mdi-microphone-off' : 'mdi-microphone'
      }`}
    ></span>
  </button>
</form>

      </div>
      <div className="btn-back">
        <Link to="/main-page" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
    </div>
  );
};

export default ChatComponent;