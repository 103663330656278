import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

const MenuEdit = ({ isOpen, toggleModal }) => {
  const { menuName } = useParams();
  const [menuData, setMenuData] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const [newMenuItem, setNewMenuItem] = useState({
    Menu: "",
    Dietary_restrictions: "",
  });
  const localStorageKey = `editedData_${menuName}`;
  const [currentlyEditedMealId, setCurrentlyEditedMealId] = useState(null);

  const fetchMenuData = useCallback(() => {
    fetch(`http://192.168.0.37:5000/Get_data?name=${menuName}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        console.log("MenuEdit API Response:", responseData);
        setMenuData(responseData.Response);
        if (Array.isArray(responseData.Response)) {
          setMenuData(responseData.Response);
        } else {
          console.error("Invalid API response:", responseData);
          setMenuData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
        setMenuData([]);
      });
  }, [menuName]);

  useEffect(() => {
    fetchMenuData();
  }, [fetchMenuData]);

  useEffect(() => {
    const storedEditedData = JSON.parse(localStorage.getItem(localStorageKey));
    if (storedEditedData) {
      setEditedData(storedEditedData);
    }
  }, [localStorageKey]);

  useEffect(() => {
    if (isSaving) {
      const apiUrl = "http://192.168.0.37:5000/menu_CRUD";

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method: "U",
          mealId: editedData.mealId,
          menuDetails: editedData.menuDetails,
        }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("Menu updated successfully:", responseData);
          setEditedData({});
          setIsSaving(false);
          fetchMenuData();
        })
        .catch((error) => {
          console.error("Error updating menu:", error);
          setIsSaving(false);
        });
    }
  }, [isSaving, editedData, fetchMenuData]);

  const handleDeleteClick = (mealId) => {
    const apiUrl = "http://192.168.0.37:5000/menu_CRUD";

    console.log("Deleting menu item with mealId:", mealId);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "D",
        mealId: mealId,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("Menu item deleted successfully:", responseData);
        fetchMenuData();
      })
      .catch((error) => {
        console.error("Error deleting menu item:", error);
      });
  };

  const handleAddMenuItem = (e) => {
    e.preventDefault();
    if (!newMenuItem.Menu || !newMenuItem.Dietary_restrictions) {
      alert("Please enter values for both Menu and Dietary Restrictions.");
      setShowAddNewItem(false);
      return;
    }

    const apiUrl = "http://192.168.0.37:5000/menu_CRUD";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "C", 
        menuDetails: newMenuItem.Menu,
        dietaryRestrictions: newMenuItem.Dietary_restrictions,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("Menu item added successfully:", responseData);
        setNewMenuItem({
          Menu: "",
          Dietary_restrictions: "",
        });
        fetchMenuData();
        setShowAddNewItem(false);
      })
      .catch((error) => {
        console.error("Error adding menu item:", error);
      });
  };

  const handleEditClick = (mealId, menuDetails) => {
    setEditedData({ mealId, menuDetails });
    setCurrentlyEditedMealId(mealId);
  };

  // const handleEditInputChange = (e, mealId) => {
  //   const { name, value } = e.target;
  //   setEditedData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //     mealId: mealId,
  //   }));
  // };
  

  const handleSaveClick = () => {
    setIsSaving(true);
    setCurrentlyEditedMealId(null);
  };

  return (
    <div>
      <Modal isOpen={true} onRequestClose={toggleModal}>
      <button onClick={toggleModal} className="btn btn-dark" style={{display: 'none'}}>Close</button>
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col-md-8">
              <strong>Menu Items</strong>
              <div className="card mt-2 border-0">
                <div className="card-body">
                  <div className="menu-container">
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>mealid</th>
                          <th>menudetails</th>
                          <th>dietaryrestrictions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {menuData.map((item, index) => (
                          <tr key={index}>
                            <td>{item[0]}</td>
                            <td>
                              {currentlyEditedMealId === item[0] ? (
                                <input
                                  type="text"
                                  value={editedData.menuDetails || ""}
                                  onChange={(e) =>
                                    setEditedData({
                                      ...editedData,
                                      menuDetails: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                item[1] || "N/A"
                              )}
                            </td>
                            <td>{item[2] || "N/A"}</td>
                            <td>
                              {currentlyEditedMealId === item[0] ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={handleSaveClick}
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    handleEditClick(item[0], item[1])
                                  }
                                >
                                  Edit
                                </button>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleDeleteClick(item[0])}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <strong style={{ color: "white", fontSize: "24px" }}>
                Add New Menu Item
              </strong>
              <div className="card mt-2 border-0">
                <div className="card-body">
                 
                  {!showAddNewItem && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setShowAddNewItem(true)}
                      data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                    >
                      Add New Item
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="btn-back">
            <Link to="/settings" title="Back">
              <span className="mdi mdi-arrow-left text-black"></span>
            </Link>
          </div>
        </div>
      </Modal>
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
      {showAddNewItem && (
                    <form onSubmit={handleAddMenuItem}>
                    <div class="modal-body">
                      <div className="my-3">
                        <label htmlFor="Menu">Menu:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Menu"
                          name="Menu"
                          value={newMenuItem.Menu}
                          onChange={(e) =>
                            setNewMenuItem({
                              ...newMenuItem,
                              Menu: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="Dietary_restrictions">
                          Dietary Restrictions:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Dietary_restrictions"
                          name="Dietary_restrictions"
                          value={newMenuItem.Dietary_restrictions}
                          onChange={(e) =>
                            setNewMenuItem({
                              ...newMenuItem,
                              Dietary_restrictions: e.target.value,
                            })
                          }
                        />
                      </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          <i className="mdi mdi-plus"></i> Add Menu Item
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setShowAddNewItem(false);
                            setNewMenuItem({
                              Menu: "",
                              Dietary_restrictions: "",
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
      </div>
      
       
    </div>
  </div>
</div>
  );
};

export default MenuEdit;
