import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import logo from './images/logo.png';
//import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { addDays } from 'date-fns';
import { GoogleLogin } from '@react-oauth/google';
//import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";

const CreateAccount = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [passwordTyping, setPasswordTyping] = useState(false);
  const navigate = useNavigate();
  const [dob] = useState("");
  const [gender, setGender] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePasswordTyping = () => {
    setPasswordTyping(true);
  };

  const handlePasswordBlur = () => {
    setPasswordTyping(false);
  };

  const checkPasswordStrength = (value) => {
    setPasswordStrength({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    let value = e.target.value;

    if (value.length > 30) {
      value = value.slice(0, 30);
    }

    setPassword(value);
    setPasswordError("");
    checkPasswordStrength(value);
  };

  const isPasswordValid = () => {
    const passwordLength = password.length;
    return (
      passwordLength >= 8 &&
      passwordLength <= 30 &&
      passwordStrength.uppercase &&
      passwordStrength.lowercase &&
      passwordStrength.number &&
      passwordStrength.specialChar
    );
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!name || !username || !password || !confirmPassword || !dob || !gender) {
      setPasswordError("Please enter data in all fields.");
      return;
    }
  
    if (password !== confirmPassword) {
      setPasswordError("Passwords didn't match");
      return;
    }
  
    const dobDate = typeof dob === 'string' ? new Date(dob) : dob;

    const data = {
      Username: username,
      Password: password,
      Name: name,
      DOB: dobDate ? dobDate.toISOString() : "",
      Gender: gender,
    };

    console.log("Submitting data to server:", data);
    console.log("Name:", name);
    console.log("Username:", username);
    console.log("Password:", password);
    console.log("Confirm Password:", confirmPassword);
    console.log("DOB:", dob);
    console.log("Gender:", gender);

  
    try {
      const response = await fetch('https://apps.hygiahealthservices.com/services/Signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log("Server response:", response);
  
      if (response.status === 200) {
        const responseData = await response.json();
        console.log("Response data from server:", responseData); 
        if (responseData.ID) {
          setAccountCreated(true);
          window.alert("Account Created Successfully");
  
          setTimeout(() => {
            navigate('/');
          }, 2000);
        } else if (responseData.usernameExists) {
          if (responseData.existingUsernames) {
            const lowercaseExistingUsernames = responseData.existingUsernames.map((u) => u.toLowerCase());
            
            if (lowercaseExistingUsernames.includes(username.toLowerCase())) {
              alert("Username already exists.");
              return; 
            }
          }
        } else {
          console.log("Server returned an error:", response.status); 
          alert("Account creation failed. Please try again.");
        }
      } else {
        alert("Account creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the account.");
    }
  }; 

  // const handleDateChange = (event) => {
  //   const input = event.target.value;
  
  //   // Allow only digits, slashes, and hyphens
  //   const cleanedInput = input.replace(/[^0-9/-]/g, '');
  
  //   // Ensure only digits, slashes, and hyphens are allowed
  //   if (/^[0-9/-]*$/.test(cleanedInput)) {
  //     // Format the date with slashes
  //     let formattedDate = '';
  //     if (cleanedInput.length > 4) {
  //       formattedDate = `${cleanedInput.slice(0, 4)}/${cleanedInput.slice(4, 6)}/${cleanedInput.slice(6, 10)}`;
  //     } else {
  //       formattedDate = cleanedInput;
  //     }
  
  //     // Update the state
  //     setDOB(formattedDate);
  //   } else {
  //     // If invalid characters are detected, don't update the state and show an alert
  //     alert("Please enter only digits, /, and -.");
  //   }
  // };
  
  // const handleDateChange = (selectedDate) => {
  //   if (selectedDate) {
  //     const year = selectedDate.getFullYear();
  //     const month = (selectedDate.getMonth() + 1).toString().padStart(2,'0');
  //     const day = selectedDate.getDate().toString().padStart(2,'0');
  
  //     const formattedDate = `${year}-${month}-${day}`;
  
  //     // Update the state
  //     setDOB(formattedDate);
  //   } else {
  //     // If an invalid date is selected, show an alert
  //     alert("Please select a valid date.");
  //   }
  // };  

  // const handleGoogleLoginSuccess = (user) => {
  //   console.log("Google login success:", user);
  //   navigate('/google-sign-in');
  // };
  
  // const handleGoogleLoginFailure = (error) => {
  //   console.error("Google login failed:", error);
  // };

  return (
    <div>
    <div className="login-container reg-page">
      <div className="lgimg-box reg-form-logo">
        <img src={logo} alt=""/>
      </div>
      {accountCreated ? (
        <div className="msg-create">
          <h2>Account Created Successfully</h2>
        </div>
      ) : (
        <div className="login-box" style={{backgroundColor:"transparent",boxShadow:"none",padding:'0'}}>
        <div className="reg-form">
          <div className="mb-4 mt-3 login-title">
            <h2>Welcome, Create your account</h2>
            <span>Enter your details</span>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group py-2">
              <div className="input-field">
              <span className="mdi mdi-account-outline p-2 label-icon"></span>
                <input
                  type="text"
                  id="name"
                  className="form-control ps-4"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter your name"
                />
              </div>
            </div>
            <div className="form-group py-2">
              <div className="input-field">
                <span className="mdi mdi-account-outline p-2 label-icon"></span>
                <input
                  type="text"
                  id="username"
                  className="form-control ps-4"
                  value={username}
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                />
              </div>
            </div>
            <div className="form-group py-2">
              <div className="input-field position-relative">
                <span className="mdi mdi-lock-outline p-2 label-icon"></span>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className={`form-control ${passwordTyping && !isPasswordValid() ? 'is-invalid' : ''} ps-4 pwd-info-icon`}
                  value={password}
                  onChange={(e) => { handlePasswordChange(e); handlePasswordTyping(); }}
                  onBlur={handlePasswordBlur}
                  placeholder="Enter Password"
                />
                <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                  <input
                    type="checkbox"
                    onChange={handleTogglePasswordVisibility}
                  />
                  <i className="mdi"></i>
                </label>
              </div>
              {passwordTyping && !isPasswordValid() && (
                <div className="text-danger mt-2">
                  Please ensure the password meets the following requirements:
                  <ul>
                    {!passwordStrength.length && <li>Minimum 8 characters</li>}
                    {!passwordStrength.uppercase && <li>At least one uppercase letter</li>}
                    {!passwordStrength.lowercase && <li>At least one lowercase letter</li>}
                    {!passwordStrength.number && <li>At least one number</li>}
                    {!passwordStrength.specialChar && <li>At least one special character</li>}
                  </ul>
                </div>
              )}
            </div>
            <div className="form-group py-2">
              <div className="input-field position-relative">
                <span className="mdi mdi-lock-outline p-2 label-icon"></span>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  className="form-control ps-4 pwd-info-icon"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Confirm Password"
                />
                <label className="ml-2 checkbox-label checkbox-label input eye-icon">
                  <input
                    type="checkbox"
                    onChange={handleToggleConfirmPasswordVisibility}
                  />
                  <i className="mdi"></i>
                </label>
              </div>
            </div>
            <div className="form-group py-2">
              <div className="input-field position-relative">
                <input type="date" className="form-control"/>
              </div>
            </div>
            {/* <div className="form-group py-2">
              <div className="input-field">
                <span className="mdi mdi-calendar p-2 label-icon"></span>
                <ReactDatePicker
                  selected={dob ? new Date(dob) : null}
                  onChange={handleDateChange}
                  dateFormat="yyyy/MM/dd"  
                  inputFormat="yyyy/MM/dd"  
                  placeholderText="Enter your Date of Birth (yyyy/MM/dd)" 
                  className="form-control"
                  customInput={
                    <input
                      type="text"
                      className="form-control ps-4 "
                      placeholder="Enter your Date of Birth (YYYY/MM/DD)"
                    />
                  }
                  showYearDropdown
                  scrollableYearDropdown
                  maxDate={addDays(new Date(), 0)}
                  previousMonthButtonLabel={<span className="black-text">&#60;</span>}
                  nextMonthButtonLabel={<span className="black-text">&#62;</span>}
                />
              </div>
            </div> */}
            <div className="form-group py-2">
              <div className="input-field">
                <span className="mdi mdi-gender-male-female p-2 label-icon"></span>
                  <select
                    id="gender"
                    className="form-select ps-4"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="" disabled>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
              </div>
            </div>
            {passwordError && <div className="text-danger">{passwordError}</div>}
            <div className="text-end">
              <button type="submit" className={`mt-3 btn btn-success text-white ${!passwordStrength.length ? 'disabled' : ''}`}>Create Account</button>
            </div>
          </form>
</div>
          <div>
    <div className="or-line"></div>
    <div className="mt-3 pb-4 text-center g-btn">
        <GoogleLogin 
          onSuccess={credentialResponse => {
            console.log(credentialResponse);
            
            console.log(credentialResponse.credential);
            const decoded = jwtDecode(credentialResponse.credential);
          console.log(decoded); 
          //console.log('Name:'+decoded.name); 
          //console.log('Email ID:'+decoded.email); 
          localStorage.setItem('gEmailID', decoded.email);
          localStorage.setItem('gName', decoded.name);
            navigate('/google-sign-in'); 
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div>
      </div> 
        </div>
      )}
      <div className="btn-back">
        <Link to="/" title="Back">
          <span className="mdi mdi-arrow-left text-white"></span>
        </Link>
      </div>
      
    </div>
    
    </div>
  );
};

export default CreateAccount;
